import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.template.html',
    standalone: true,
    imports: [NgIf, MatIconModule, TranslateModule],
    styleUrls: ['./pagination.style.scss'],
})
export class PaginationComponent implements OnChanges {
    @Input() scroll = true;
    @Input() size = 'large';
    @Input() hideRange = false;

    @HostBinding('class.fixed')
    @Input()
    fixed = false;

    /**
     * Total Number Of Results
     */
    @Input() total = 0;
    /**
     * Current Page
     */
    @Input() current = 1;

    @Input() limit = 25;

    @Input() itemType = '';

    @Input() disableLoad = false;

    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    // For ngFor count loop
    Arr = Array;

    private totalPages = 0;

    ngOnChanges(): void {
        if (this.total !== null && this.limit) {
            this.totalPages = Math.ceil(this.total / this.limit);
        }
    }

    goToPage(page: number): void {
        if (!this.disableLoad) {
            this.pageChange.emit(page);
        }

        if (this.scroll) {
            this.scrollPageTop();
        }
    }

    scrollPageTop(): void {
        setTimeout(() => {
            const body = $('html, body');
            body.stop().animate({ scrollTop: 0 }, 250, function () {});
        });
    }

    isDefined(value: number): boolean {
        return typeof value !== 'undefined';
    }

    pager(): any {
        const pages = this.getPager();
        return pages;
    }

    trackBy(item): any {
        return item;
    }

    getTotal(): number {
        return this.total;
    }

    getTotalPages(): number {
        return this.totalPages;
    }

    getShowing(): string | null {
        if (this.getShowingFirst() === this.getShowingLast()) {
            return null;
        }

        if (this.hideRange) {
            return (this.getShowingFirst() + 1).toString();
        }

        return this.getShowingFirst() + 1 + '-' + this.getShowingLast();
    }

    showFirst(): boolean {
        if (this.current > 2) {
            return true;
        }
    }

    showLast(): boolean {
        if (this.current < this.totalPages - 3) {
            return true;
        }
    }

    showPrevious(): boolean {
        if (this.current > 2) {
            return true;
        }
    }

    showNext(): boolean {
        if (this.current < this.totalPages - 3) {
            return true;
        }
    }

    getPager() {
        // calculate total pages
        const totalPages = this.totalPages;

        let startPage: number, endPage: number;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (this.current <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (this.current + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = this.current - 5;
                endPage = this.current + 4;
            }
        }

        // calculate start and end item indexes
        const startIndex = (this.current - 1) * this.limit;
        const endIndex = Math.min(startIndex + this.limit - 1, this.total - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = [];
        for (let i = startPage; i < endPage + 1; i++) {
            pages.push(i);
        }

        // return object with all pager properties required by the view
        return {
            totalItems: this.total,
            currentPage: this.current,
            pageSize: this.limit,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages,
        };
    }

    private getShowingFirst(): number {
        return (this.current - 1) * this.limit;
    }

    private getShowingLast(): number {
        let last = (this.current - 1) * this.limit + this.limit;
        if (last > this.total) {
            last = this.total;
        }

        return last;
    }
}
