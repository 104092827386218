import { Paginator } from '@app/classes';
import { SimplifiedPaginator } from '@app/classes/simplified-paginator';
import { TaxReturn } from '@app/models/payroll/tax-return.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { T4Summary } from '@models/payroll/t4-summary.model';
import { of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { GeneratedOnColumn, StatusColumn, TitleColumn } from './columns';

/**
 * An instance of Paginator that contains only unpersisted data. This is necessary for
 * users to be able to click into new tax returns, from where they will be able to
 * generate and persist them.
 *
 * We can add more / different records to this array as needed.
 */
export class NonGeneratedTaxReturnPaginator<T extends T4Summary | TaxReturn> extends SimplifiedPaginator<T> {
    constructor(_staticValues: T[]) {
        super(of(_staticValues), { shouldAutoLoadData: true }); // shouldAutoLoadData required because this paginator is instantiated after the table has already loaded
    }
}

export class TaxReturnsTable extends Table<TaxReturn> {
    links = true;
    emitRowClickedWithLinksEnabled = true;

    constructor(
        auth: AuthService,
        private year: number,
        private shouldPrependYearToLink = false
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [new TitleColumn(this.year), new GeneratedOnColumn(), new StatusColumn()];
    }

    /**
     * Attempt to load the TaxReturn for the year.
     *
     * If the index is empty, swap the paginator for an instance of NonGeneratedTaxReturnPaginator,
     * so that we have our default row(s) in the table
     */
    setDataSource(): void {
        this.onLoading
            .pipe(
                filter((loading) => !loading),
                take(1)
            )
            .subscribe(() => {
                if (this.paginator?.empty) {
                    this.paginator = new NonGeneratedTaxReturnPaginator([new TaxReturn()]);
                }
            });

        this.paginator = new Paginator(TaxReturn.where('year', this.year.toString()));
    }

    getLink(taxReturn: TaxReturn): string[] | null {
        if (!taxReturn.id) {
            return null;
        }

        return this.shouldPrependYearToLink
            ? [this.year.toString(), taxReturn.id.toString()]
            : [taxReturn.id.toString()];
    }
}
