import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { Components } from '@app/components';
import { Directives } from '@app/directives';
import { AppDatePipe, Pipes } from '@app/pipes';
import { environment } from '@env/environment';
import { HotTableModule } from '@handsontable/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { registerAllModules } from 'handsontable/registry';
import moment from 'moment';
import 'moment/locale/fr-ca.js';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { WebBadgeComponent } from './components/humi-design-library/badge/badge.component';
import { WebHumiLogoComponent } from './components/humi-design-library/humi-logo/humi-logo.component';
import { WebTipComponent } from './components/humi-design-library/tip/tip.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { BannerComponent, ButtonComponent, CardComponent } from './components/platform';
import { SegmentHeaderComponent } from './components/segment-header/segment-header.component';
import { ShowIfFeatureFlagTrueDirective } from './directives/feature-flag/show-if-feature-flag';
import { PermissionDirective } from './directives/permission.directive';
import { MaterialModules, MaterialProviders } from './material-config';
import { AppTranslatePipe } from './pipes/app-translate.pipe';
import { SecurePipe } from './pipes/secure.pipe';

moment.locale('en-ca');

registerAllModules();
LicenseManager.setLicenseKey(environment.agGridEnterpriseKey);

export function momentAdapterFactory(): DateAdapter {
    return adapterFactory(moment);
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ...MaterialModules,
        ScrollingModule,
        DragDropModule,
        MatTreeModule,
        HotTableModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        AgGridModule,
        TranslateModule.forChild(),
        PortalModule,
        WebHumiLogoComponent,
        WebBadgeComponent,
        WebTipComponent,
        ShowIfFeatureFlagTrueDirective,
        BackButtonComponent,
        ButtonComponent,
        AvatarComponent,
        SecurePipe,
        AppTranslatePipe,
        CardComponent,
        PaginationComponent,
        SegmentHeaderComponent,
        PermissionDirective,
        EmptyStateComponent,
        BannerComponent,
        AppDatePipe,
    ],
    declarations: [...Directives, ...Pipes, ...Components],
    providers: [MaterialProviders],
    exports: [
        ...MaterialModules,
        ScrollingModule,
        DragDropModule,
        MatTreeModule,
        HotTableModule,
        AgGridModule,
        CalendarModule,
        WebBadgeComponent,
        WebTipComponent,
        BackButtonComponent,
        ButtonComponent,
        AvatarComponent,
        CardComponent,
        SegmentHeaderComponent,
        PaginationComponent,
        SecurePipe,
        AppTranslatePipe,
        EmptyStateComponent,
        BannerComponent,
        PermissionDirective,
        AppDatePipe,
        ...Directives,
        ...Pipes,
        ...Components,
    ],
})
export class PlatformModule {}
