import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';

export abstract class GoalAssignment extends Model {
    // protected static _resource = 'performanceManagement/companies/:company/goals'; not yet implemented

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    /**
     *
     * @returns {Employee}
     */
    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    /* Timestamps */
    get createdAt(): Date {
        return this._attributes['createdAt'];
    }

    get updatedAt(): Date {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): Date {
        return this._attributes['deletedAt'] || null;
    }

    get isComplete(): boolean {
        return this._attributes['isComplete'];
    }
}
