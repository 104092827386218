import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import moment from 'moment';

export class StartEndTimeColumn extends Column {
    title = 'time-tracking.timesheetsIndexPage.table.startEndTime';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
    sortField = 'timeClockEntry.startTime';

    getDisplayPropertyValue(timesheetEntry: TimesheetEntry): string {
        if (!timesheetEntry.timeClockEntry) {
            return '-';
        }

        return (
            moment(timesheetEntry.timeClockEntry?.startTime).format('h:mm A') +
            ' - ' +
            moment(timesheetEntry.timeClockEntry?.endTime).format('h:mm A')
        );
    }
}
