import { Injectable } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import { TaxFormBox } from '@app/models/payroll/tax-form-box.abstract.model';
import { TaxForm } from '@app/models/payroll/tax-form.abstract.model';
import { TaxReturnSummary } from '@app/models/payroll/tax-return-summary.abstract.model';
import { TaxReturn } from '@app/models/payroll/tax-return.model';
import { AuthService } from '@app/services';
import { TranslatableKey } from '@app/types/translatable.type';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { RL1Service } from './rl1.service';
import { T4Service } from './t4.service';

@Injectable()
export class TaxReturnService {
    constructor(
        private authService: AuthService,
        private t4Service: T4Service,
        private rl1Service: RL1Service
    ) {}

    /**
     * Creates a tax return and saves it in the backend
     */
    generateTaxReturn(taxReturn?: TaxReturn): Observable<TaxReturn> {
        if (!taxReturn) {
            taxReturn = new TaxReturn();
        }

        return from(taxReturn.save());
    }

    issue(summary: TaxReturnSummary, { isManualSubmit }: { isManualSubmit: boolean }): Observable<TaxReturnSummary> {
        if (summary.isRL1Summary()) {
            return this.rl1Service.issue(summary, isManualSubmit);
        } else if (summary.isT4Summary()) {
            return this.t4Service.issue(summary.id, isManualSubmit);
        }
        throw new Error('Unimplemented Summary type');
    }

    /**
     * Gets boxes relevant to a tax form
     */
    getBoxes(taxReturn: TaxForm): Observable<{ title?: TranslatableKey; boxes: TaxFormBox[] }[]> {
        if (taxReturn.isRL1()) {
            return this.rl1Service.getBoxes(taxReturn);
        } else if (taxReturn.isT4()) {
            return this.t4Service.getBoxes(taxReturn);
        }
        throw new Error('Unimplemented Tax Form type');
    }

    /**
     * Reverts an RL-1 or T4 to its original state
     */
    revertToOriginalState(taxReturn: TaxForm): Observable<TaxForm> {
        if (taxReturn.isRL1()) {
            return this.rl1Service.revertToOriginalState(taxReturn);
        } else if (taxReturn.isT4()) {
            return this.t4Service.revertToOriginalState(taxReturn);
        }
        throw new Error('Unimplemented Tax Form type');
    }

    /**
     * Gets the employee associated with a T4 or RL-1
     */
    getEmployee(taxReturn: TaxForm): Observable<Employee | undefined> {
        return from(
            Employee.param('company', this.authService.company.id)
                .where('prEmployeeId', taxReturn.employee.id)
                .whereIn('status', ['active', 'terminated', 'on leave'])
                .get()
        ).pipe(map(([employees]) => (employees.length ? employees[0] : undefined))); // There should only ever be 1 employee returned, but we need to call get() because we're filtering by ID
    }
}
