import { ModelMixin } from '../core/base-generic.model';
import { Rl1Box } from './rl1-box.model';
import { T4 } from './t4.model';
import { TaxFormBox } from './tax-form-box.abstract.model';

export class T4Box extends ModelMixin<T4Box>() implements TaxFormBox {
    protected static _version = 'v2';
    protected static _resource = 'payroll/t4Boxes';

    /*
        Using parseInt here as we are comparing this id with selectedIds
        in many places within our logic and this id is coming in as
        a string
    */
    get id(): number {
        let t4BoxId = this._attributes['id'];
        if (typeof t4BoxId === 'string') {
            t4BoxId = parseInt(t4BoxId);
        }
        return t4BoxId;
    }

    get code(): string {
        return this._attributes['code'];
    }

    get boxNumber(): string {
        return this._attributes['boxNumber'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get other(): boolean {
        return this._attributes['other'];
    }

    get inEmploymentIncome(): boolean {
        return this._attributes['inEmploymentIncome'];
    }

    get taxYear(): number {
        return this._attributes['taxYear'];
    }

    get xmlName(): string {
        return this._attributes['xmlName'];
    }

    get xmlNameCamel(): keyof T4 {
        return this.camelize(this._attributes['xmlName']) as keyof T4;
    }

    get isCurrency(): boolean {
        // box 45 is dental benefit code (1 thru 5) and box 50 is RPP or DPSP registration number
        return this.boxNumber !== '45' && this.boxNumber !== '50';
    }

    isRL1(): this is Rl1Box {
        return false;
    }
    isT4(): this is T4Box {
        return true;
    }

    private camelize(str: string): string {
        return str.replace(/(\_\w)/g, function (k: string): string {
            return k[1].toUpperCase();
        });
    }
}
