import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { Company } from '@models/company/company.model';

/**
 * Inteded to be used with company attributes that return boolean values. Allow access
 * when that attribute returns true.
 */
@Injectable()
export class CompanyAttributeGuard {
    constructor(
        private auth: AuthService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const attributeName = route.data['companyAttributeName'] as string;

        const company = this.auth.company;

        if (!company) {
            this.router.navigate(['/']);
            return false;
        }

        if (attributeName in company) {
            return Boolean(company[attributeName as keyof Company]);
        }

        return false;
    }
}
