import { ElementRef, Injectable } from '@angular/core';
import { inLocal } from '@app/functions/in-local';
import { inProduction } from '@app/functions/in-production';
import { TranslationStorageService } from '@app/services/translation-storage.service';
import {
    CreateTemplateEventPayload,
    ErrorEventPayload,
    SendEventPayload,
    SignEventPayload,
} from '@app/types/hello-sign-namespace.type';
import { environment } from '@env/environment';
import { hslToHex } from '@humi-design-library/theme/palette/utilities';
import HelloSign from 'hellosign-embedded';
import { ColorService } from './color.service';

/** @deprecated */
export type HelloSignResponse = {
    closedByUser: boolean;
    meta: any;
};

export type SignatureResponse = {
    signatureId: string;
};

export type TemplateResponse = {
    templateId: string;
    templateInfo: {
        title: string;
    };
};

@Injectable()
export class HelloSignEmbedService {
    private client: HelloSign;
    private defaultHelloSignResponseValues = {
        closedByUser: false,
        meta: null,
    };

    constructor(
        private translationStorage: TranslationStorageService,
        colorService: ColorService
    ) {
        this.client = new HelloSign({
            clientId: environment.helloSignClientId,
            skipDomainVerification: inLocal(),
            debug: !inProduction(),
            ...this.getLanguageOptions(),
            //see https://developers.hellosign.com/api/reference/premium-branding/
            whiteLabeling: {
                page_background_color: hslToHex(colorService.getCSSVariable('--card-background-color')),
                header_background_color: hslToHex(colorService.getCSSVariable('--card-background-color')),
                text_color1: colorService.getHexColorValue('black'),
                text_color2: colorService.getHexColorValue('grey', 900),
                link_color: colorService.getHexColorValue('primary', 600),
                primary_button_color: colorService.getHexColorValue('primary', 600),
                primary_button_text_color: colorService.getHexColorValue('white'),
                primary_button_color_hover: colorService.getHexColorValue('primary', 600),
                primary_button_text_color_hover: colorService.getHexColorValue('white'),
                secondary_button_color: colorService.getHexColorValue('white'),
                secondary_button_text_color: colorService.getHexColorValue('primary', 600),
                secondary_button_color_hover: colorService.getHexColorValue('white'),
                secondary_button_text_color_hover: colorService.getHexColorValue('primary', 600),
                legal_version: 'terms2',
            },
        });
    }

    /**
     * The current types file for HelloSign has an implementation of multiple overloaded functions for example:
     *
     * on(name: Events["CLOSE"] | Events["CANCEL"] | Events["FINISH"], cb: () => void): void;
     * on(name: Events["CREATE_TEMPLATE"], cb: (data: CreateTemplateEventPayload) => void): void;
     * on(name: Events["DECLINE"], cb: (data: DeclineEventPayload) => void): void;
     *
     * This presents a issue of trying to create a general function which can take all types and ensure type safety
     * without TS complaining.
     *
     * Create functions as we need like the ones below for use cases as they come up. This way
     * we ensure type safety and have a central service that makes it easy to trace.
     */

    onEndOnce(
        event: typeof HelloSign.events.CANCEL | typeof HelloSign.events.FINISH | typeof HelloSign.events.CLOSE,
        callback: () => void
    ): void {
        this.client.once(event, callback);
    }

    onCreateTemplateEventOnce(callback: (data: CreateTemplateEventPayload) => void): void {
        this.client.once(HelloSign.events.CREATE_TEMPLATE, callback);
    }

    onErrorEventOnce(callback: (data: ErrorEventPayload) => void): void {
        this.client.once(HelloSign.events.ERROR, callback);
    }

    onSignEventOnce(callback: (data: SignEventPayload) => void): void {
        this.client.once(HelloSign.events.SIGN, callback);
    }

    onSendEventOnce(callback: (data: SendEventPayload) => void): void {
        this.client.once(HelloSign.events.SEND, callback);
    }

    /**
     * removeSignCallbacks removes any callbacks we have previously registered.
     *
     * You might want to remove a callback if an employee is looking at a different document.
     */
    removeSignCallbacks(): void {
        this.client.off(HelloSign.events.SIGN);
    }

    /**
     * Open the template UI in hello sign and allow user to create/edit a template, on success, return the
     * SuccessResponse with the template id.
     */
    async open(helloSignUrl: string, container: ElementRef, cancellable = false): Promise<HelloSignResponse> {
        return new Promise((resolve, reject) => {
            this.client.open(helloSignUrl, {
                container: container?.nativeElement,
                allowCancel: cancellable,
            });
            /**
             * @deprecated
             * Do not use the events in this manner. Rather read above to use them in the new manner.
             * This has been left because the use of the Promise and response concept here.
             */
            this.client.once(HelloSign.events.SIGN, (data: SignatureResponse) => {
                this.destroy();
                const response: HelloSignResponse = {
                    ...this.defaultHelloSignResponseValues,
                    meta: data,
                };
                resolve(response);
            });
            this.client.once(HelloSign.events.CREATE_TEMPLATE, (data: TemplateResponse) => {
                this.destroy();
                const response: HelloSignResponse = {
                    ...this.defaultHelloSignResponseValues,
                    meta: data,
                };
                resolve(response);
            });
            this.client.once(HelloSign.events.ERROR, (data) => {
                reject(data);
            });
            // when iframe is closed for any reason, destroy the client - avoids repeat calls to listeners
            this.client.once(HelloSign.events.CLOSE, () => {
                //if user signs or closes frame this will be called
                this.destroy();
            });
            this.client.once(HelloSign.events.CANCEL, () => {
                //ONLY if user closes before filling/signing then this will be called
                this.destroy();
                const response: HelloSignResponse = {
                    ...this.defaultHelloSignResponseValues,
                    closedByUser: true,
                };
                resolve(response);
            });
        });
    }

    destroy(): void {
        /**
         * !important
         * Close does not just "close" the iframe, it will also ensure
         * the `createTemplate` event is detached in the SDK.
         */
        this.client.close();
    }

    /**
     * The locale code that will be used to determine which language the embedded request will be displayed in.
     * If no locale is specified HelloSign will attempt to determine the user's preferred language by their browser
     * settings or fall back to English.
     *
     * List of HelloSign's supported languages:
     *  https://faq.hellosign.com/hc/en-us/articles/216145508
     *  https://github.com/hellosign/hellosign-embedded/blob/master/src/settings.js#L48-L79
     *
     */
    private getLanguageOptions(): { [key: string]: string } {
        const locale = this.translationStorage.locale;
        if (locale && locale.toLocaleLowerCase().startsWith('fr')) {
            const lang = 'fr-FR';
            return {
                userCulture: lang, // HelloSign Embedded V1
                locale: lang, // HelloSign Embedded V2
            };
        }
        return {};
    }
}
