import { Paginator, SortDirection } from '@app/classes';
import { ModelUnion as Model } from '@app/models/core/base.model';
import { Column } from '@app/modules/table/classes/column.class';
import { Filter } from '@app/modules/table/classes/filter.abstract';
import { FilterValueChange } from '@app/modules/table/interfaces/filter-value-change.interface';
import { FilterValues } from '@app/modules/table/interfaces/filter-values.interface';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { FilterValue } from '@app/modules/table/types/filter-value.type';
import { AuthService } from '@app/services';
import { TableMessageService } from '@table/classes/table-message.service';
import { isArray, isEmpty, isNil } from 'lodash-es';
import set from 'lodash-es/set';
import { ReplaySubject, Subscription, merge } from 'rxjs';
import { TableFilter } from './table-filters/table-filter.abstract';

const TOTAL_TABLE_SIZE = 100; // Going for percent here
const COLUMN_WIDTH_CSS_UNIT = '%';

export abstract class Table<T = Model, M = {}> {
    /**
     * Determines whether rows in app-table can be dragged and dropped
     */
    allowDragAndDrop = false;

    /**
     * Determines whether to show grippy icon on hovering over bulk-select checkbox feature
     */
    showGrippyOnCheckbox = false;

    /**
     * Determines if each row should show a checkbox selection for bulk select
     */
    bulkSelect = false;

    /**
     * Determines if row items should link somewhere. The link will be the 'id' field of the item by default.
     */
    links = false;

    emitRowClickedWithLinksEnabled = false;

    unnavigableLink = false;

    /**
     * The current active filter values. This is iterated over and nulls are cleared out before submitting a request.
     */
    activeFilters: Map<string, FilterValue> = new Map<string, FilterValue>();

    /**
     * If the table should have additional actions per row (button in the case of 1, meatball menu otherwise)
     */
    showMenuColumnOptions = false;

    paginator: Paginator<T, M>;
    columns: Column[] = [];

    /**
     * sortColumn is the column that the table is sorted by initially
     * individual columns can also decide if they are sortable
     */
    sortColumn: Column | null = null;

    sortDirection: SortDirection = SortDirection.DESC;
    columnWidths: Map<Column, string> = new Map<Column, string>();
    filters: TableFilter[] = [];

    onLoading: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    onFilterChanged: ReplaySubject<FilterValueChange> = new ReplaySubject<FilterValueChange>();

    protected _authSub: Subscription;
    protected defaultSortProperty: string | null = 'id';

    constructor(
        protected auth?: AuthService,
        protected messages?: TableMessageService
    ) {
        if (auth) {
            this.subscribeToUserLoginEvents();
        }
    }

    /**
     * True if there is no unfiltered data
     */
    get isEmpty(): boolean {
        return this.paginator.empty && !this.activeFilters.size && !this.paginator.search;
    }

    /**
     * Set the columns for the table. Also, set the default sort here if it is on an explicit column.
     */
    abstract setColumns(): void;

    abstract setDataSource(): void;

    /**
     * Generates a link that each row should go to. Override this method in your class if your table should link
     * to something other than the current route with the id of a model appended to it
     */
    getLink(row: T): (string | number)[] | null {
        try {
            // Rows should be based off of models that always have IDs, but it's possibly that they do not have an id property
            const id = (row as { id: string | number })?.id;
            return [id];
        } catch {
            return null;
        }
    }

    getParams(_: Model): any {
        return {};
    }

    /**
     * The menu column options for the row. If an empty array is returned, nothing will be shown.
     */
    getMenuColumnOptionsForRow(_: Model): MenuColumnItem[] {
        return [];
    }

    hasFiltersApplied(): boolean {
        const filters = this.paginator.filters;
        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                return true;
            }
        }

        return false;
    }

    clearFilters(): void {
        if (this.paginator && (this.paginator.search !== null || this.paginator.page !== 1)) {
            this.paginator.search = null;
        }

        this.columns.forEach((c: Column) => c.clear());
        this.filters.forEach((f: TableFilter) => f.clear());
    }

    addColumn(column: Column): this {
        this.columns.push(column);

        return this;
    }

    addColumns(...columns: Column[]): this {
        this.columns = this.columns.concat(columns);

        return this;
    }

    getColumnByProperty(propertyName: string): Column {
        return this.columns.find((column) => column.property === propertyName);
    }

    reload(): void {
        this.paginator.loadData();
    }

    sort(column: Column, shouldReloadData = true): void {
        if (this.sortColumn !== column) {
            // No column yet selected, set to the one that was passed in
            this.sortColumn = column;
            this.sortDirection = SortDirection.ASC;
        } else if (this.sortColumn === column && this.sortDirection === SortDirection.ASC) {
            // Selected the same column, change the direction
            this.sortDirection = SortDirection.DESC;
        } else if (this.sortColumn === column && this.sortDirection === SortDirection.DESC) {
            // Clicked again to clear
            this.sortColumn = null;
            this.sortDirection = SortDirection.ASC;
        }

        this.paginator.setSort({
            column: (this.sortColumn && this.sortColumn.sortProperty) || this.defaultSortProperty,
            order: this.sortDirection,
        });

        if (shouldReloadData) {
            this.paginator.loadData();
        }
    }

    hasClearableFilters(): boolean {
        const hasClearableFilter = this.filters?.some((f: TableFilter) => f.isClearable());
        const columnsHaveClearableFilter = this.columns?.some((c) => c.filter?.isClearable());

        return !!this.paginator.search || hasClearableFilter || columnsHaveClearableFilter;
    }

    isRowValidDropZone(_: Model): boolean {
        return true;
    }

    protected boot(): void {
        this.columns = [];
        this.paginator = null;

        this.setDataSource();
        this.setColumns();
        this.setColumnWidths();
        this.paginator.onLoading((loading) => {
            this.onLoading.next(loading);
        });
        this.subscribeToAllFilters();
        this.applyInitialSort();
        this.paginator.loadData();
    }

    protected setColumnWidths(): void {
        const remainingSize = this.columns
            .filter((c) => !!c.displayWidth)
            .reduce((remainingSize: number, column: Column): number => {
                this.columnWidths.set(column, column.displayWidth + '%');
                return remainingSize - column.displayWidth;
            }, TOTAL_TABLE_SIZE);

        if (remainingSize < 0) {
            throw new Error('Total column width exceeds ' + TOTAL_TABLE_SIZE + '. Double check column widths.');
        }

        const autoWidthColumns = this.columns.filter((c) => !c.displayWidth);
        if (autoWidthColumns.length < 1) {
            return;
        }

        const autoWidth = remainingSize / autoWidthColumns.length + COLUMN_WIDTH_CSS_UNIT;
        autoWidthColumns.forEach((c) => this.columnWidths.set(c, autoWidth));
    }

    protected subscribeToAllFilters(): void {
        merge(
            ...this.columns
                .map((c: Column) => c.filter)
                .filter((filter) => filter !== undefined)
                .map((filter: Filter) => filter.onChanges),
            ...this.filters.map((filter) => filter.onChanges)
        ).subscribe((changes: FilterValueChange[]) => {
            changes.forEach((change: FilterValueChange) => {
                change.value === null
                    ? this.activeFilters.delete(change.field)
                    : this.activeFilters.set(change.field, change.value);

                this.onFilterChanged.next(change);
            });

            this.applyFilters();
        });
    }

    protected clear(): void {
        this.columns = [];
        this.paginator = null;
        this.clearFilters();
    }

    private subscribeToUserLoginEvents() {
        this.auth.onLogout.subscribe(() => {
            this.clear();
        });

        this.auth.onLogin.subscribe(() => {
            this.boot();
        });
    }

    private applyFilters(): void {
        if (!this.paginator) {
            return;
        }

        const toApply: FilterValues = {};
        this.activeFilters.forEach((value: FilterValue | FilterValue[], key) => {
            if (!(isNil(value) || (isArray(value) && isEmpty(value)))) {
                set(toApply, key, value);

                return;
            }

            delete toApply[key];
        });

        this.paginator.filters = toApply;
    }

    private applyInitialSort(): void {
        this.paginator.setSort({
            column: this.sortColumn?.sortProperty || this.defaultSortProperty,
            order: this.sortDirection,
        });
    }
}
