<p class="padding-bottom-3">
    {{ 'time-off-v3.calculateBalanceDescription' | translate: { timeOffTypeName: this.timeOffType?.name } }}
</p>
<form #form="ngForm" autocomplete="off" class="notranslate" [class.loading]="isLoading">
    <mat-calendar
        #calendar
        *ngIf="showCalendar"
        [dateClass]="dateClass"
        [(selected)]="selectedDate"
        (selectedChange)="getBalanceOnDateSelect()"
    ></mat-calendar>
    <div class="balance">
        <div class="balance-detail">
            <div>
                {{
                    'components.calculate-balance.balanceOn'
                        | translate
                            : {
                                  date: selectedDate | appDate: 'mediumDate',
                              }
                }}
            </div>
            <h3 class="no-margin">{{ balance }} {{ timeOffType?.unitOfTimePluralTranslation ?? '' | translate }}</h3>
        </div>
        <div>
            <div>
                {{
                    'components.calculate-balance.unitScheduledAfterDate'
                        | translate
                            : {
                                  date: selectedDate | appDate: 'mediumDate',
                              }
                }}
            </div>
            <h3 class="no-margin">
                {{
                    'components.calculate-balance.amountApprovedOfUnitApproved'
                        | translate
                            : {
                                  amountApproved: futureRequestsAmountApproved | number: decimalPlacesForPipe,
                                  unit: timeOffType?.unitOfTimePluralTranslation ?? '' | translate,
                              }
                }}
            </h3>
            <div *ngIf="!!futureRequestsAmountPending">
                {{
                    'components.calculate-balance.futureRequestOfUnitPending'
                        | translate
                            : {
                                  amountPending: futureRequestsAmountPending | number: decimalPlacesForPipe,
                                  unit: timeOffType?.unitOfTimePluralTranslation ?? '' | translate,
                              }
                }}
            </div>
        </div>
    </div>
</form>
<div *ngIf="this.currentDateBeforeExpiryDate && this.expiringBalance > 0" class="flex align-items-center padding-top-3">
    <div class="bullet circle"></div>
    <p
        class="padding-left-1"
        [innerHTML]="
            'time-off-v3.carryoverExpirationDaysRemaining'
                | translate
                    : {
                          days: expiringBalance,
                          unit: timeOffType?.unitOfTimePluralTranslation ?? 'time-off-v3.days' | translate,
                          carryoverExpirationDate: carryoverBalanceExpiresOn,
                      }
        "
    ></p>
</div>
