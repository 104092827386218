import { ModelMixin } from '@models/core/base-generic.model';
import { Rl1Box } from '@models/payroll/rl1-box.model';
import { T4Box } from '@models/payroll/t4-box.model';

export class BenefitType extends ModelMixin<BenefitType>() {
    protected static _resource = 'payroll/benefitTypes';

    protected static _version = 'v2';

    get name(): string {
        return this._attributes['name'];
    }

    get displayName(): string {
        return this._attributes['displayName'];
    }

    get insurable(): boolean {
        return this._attributes['insurable'];
    }

    get pretax(): boolean {
        return this._attributes['pretax'];
    }

    get taxable(): boolean {
        return this._attributes['taxable'];
    }

    get pensionable(): boolean {
        return this._attributes['pensionable'];
    }

    get qcTaxable(): boolean {
        return this._attributes['qcTaxable'];
    }

    get qcPensionable(): boolean {
        return this._attributes['qcPensionable'];
    }

    get assessable(): boolean {
        return this._attributes['assessable'];
    }

    get roeEarnings(): boolean {
        return this._attributes['roeEarnings'];
    }

    get t4BoxesDisplayList(): null | string {
        return this.t4Boxes?.map((b) => b.boxNumber).join(',');
    }

    get t4Boxes(): T4Box[] {
        return this.hasMany(T4Box, 't4Boxes');
    }

    get rl1BoxesDisplayList(): null | string {
        return this.rl1Boxes?.map((b) => b.code).join(',');
    }

    get rl1Boxes(): Rl1Box[] {
        return this.hasMany(Rl1Box, 'rl1Boxes');
    }
}
