import { ModelMixin } from '../core/base-generic.model';

/**
 * Used to resend an invite to complete UBO verification
 */
export class PersonaResendInvite extends ModelMixin<PersonaResendInvite>() {
    get inquiryId(): string {
        return this._attributes['inquiryId'];
    }

    protected static _serializeAttributes = ['inquiryId'];

    protected static _resource = 'commonComponents/resendUboInquiry' as const;
    protected static _type = 'PersonaResendUboInvite';
    protected static _version = 'v2';
}
