import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonApiResponse } from '@app/interfaces';
import { T4Box } from '@app/models/payroll/t4-box.model';
import { T4Summary } from '@app/models/payroll/t4-summary.model';
import { T4 } from '@app/models/payroll/t4.model';
import { AuthService, FileHelperService } from '@app/services';
import { TranslatableKey } from '@app/types/translatable.type';
import { sortBy } from 'lodash-es';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaxFormSet, TaxFormSetResponse } from '../interfaces/tax-forms.interface';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class T4Service {
    private _taxFormSets: TaxFormSet[];

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private fileHelper: FileHelperService
    ) {}

    async getT4Overview(): Promise<TaxFormSet[]> {
        if (this._taxFormSets) {
            return Promise.resolve(this._taxFormSets);
        }
        const url = `/v2/payroll/companies/${this.auth.company.id}/taxFormSets`;
        return this.http
            .get(url)
            .toPromise()
            .then((res: TaxFormSetResponse) => {
                return (this._taxFormSets = res.data);
            });
    }

    getPierPreview(id: number): Promise<any> {
        const url = PayrollResources.T4Preview.replace(':company', this.auth.company.id.toString()).replace(
            ':t4',
            id.toString()
        );
        return this.http.get(url).toPromise();
    }

    revertToOriginalState(t4: T4): Observable<T4> {
        const path = PayrollResources.T4Revert.replace(':company', this.auth.company.id.toString()).replace(
            ':t4',
            t4.id.toString()
        );

        return this.http.put<T4>(path, {});
    }

    sendBulkEmails(t4SummaryId: string | number): Promise<any> {
        const url = `/v2/payroll/companies/${this.auth.company.id}/t4Summaries/${t4SummaryId}/bulkEmail`;

        return this.http.put(url, {}).toPromise();
    }

    sendT4Email(t4: T4): Promise<any> {
        const url = PayrollResources.T4Email.replace(':company', this.auth.company.id.toString()).replace(
            ':t4',
            t4.id.toString()
        );
        return this.http.get(url).toPromise();
    }

    issue(summaryId: number, manualSubmit: boolean): Observable<T4Summary> {
        const url = `/v2/payroll/companies/${this.auth.company.id}/t4Summaries/${summaryId}/issue`;

        return this.http
            .put<JsonApiResponse<T4Summary>>(url, { data: { attributes: { manualSubmit } } })
            .pipe(map((response) => new T4Summary(response.data.attributes)));
    }

    getBoxes(taxReturn: T4): Observable<{ title?: TranslatableKey; boxes: T4Box[] }[]> {
        return from(T4Box.limit(100).get()).pipe(
            map(([boxes]) => [{ boxes: sortBy(boxes, (box) => taxReturn.errors[box.xmlNameCamel] == undefined) }]) // T4s are not grouped like RL-1s so can be returned as is
        );
    }

    async addAmendedT4(year: number, prEmployeeId: number): Promise<T4> {
        const t4 = new T4();
        t4.year = year;
        t4.employeeId = prEmployeeId;

        return await t4.param('company', this.auth.company.id).save();
    }

    async generateT4Summary(t4Summary?: T4Summary): Promise<T4Summary> {
        if (!t4Summary) {
            t4Summary = new T4Summary();
        }

        t4Summary.defaultContact = {
            name: this.auth.account.fullName || '',
            email: this.auth.account.email,
            phoneNumber: this.auth.company.phonePrimary,
        };

        return await t4Summary.param('company', this.auth.company.id).save();
    }

    async downloadTaxReturnPreview(t4Summary: T4Summary): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.auth.company.id}/t4Summaries/${t4Summary.id}/downloadPdfPreview`;

            this.fileHelper.savePayrollFile(url, `t4_summary_pdf_preview_${t4Summary.year}`, 'pdf', null, (e) =>
                e ? reject(e) : resolve()
            );
        });
    }

    async downloadTaxFormPreview(t4: T4): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.auth.company.id}/t4s/${t4.id}/downloadPdfPreview`;

            this.fileHelper.savePayrollFile(url, `t4_pdf_preview_${t4.year}_${t4.id}`, 'pdf', null, (e) =>
                e ? reject(e) : resolve()
            );
        });
    }

    async downloadTaxPackage(t4Summary: T4Summary): Promise<void> {
        return new Promise((resolve, reject) => {
            const year = t4Summary.year;
            const url = `${this.auth.company.id}/t4Summaries/${t4Summary.id}/download`;

            this.fileHelper.savePayrollFile(url, `T619_${year.toString()}`, 'zip', { year }, (e) =>
                e ? reject(e) : resolve()
            );
        });
    }
}
