import { Injectable } from '@angular/core';
import { SortDirection } from '@app/classes';
import { SimplifiedPaginator } from '@app/classes/simplified-paginator';
import { Column } from '@app/modules/table/classes/column.class';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService, NotifyService } from '@app/services';
import { map } from 'rxjs/operators';
import { DentalBenefitService } from '../../dental-benefit/dental-benefit.service';
import { DentalBenefitCodeCount, DentalBenefitTableRow } from '../../dental-benefit/types';
import columns from './columns';

class DentalBenefitsPaginator extends SimplifiedPaginator<DentalBenefitTableRow> {
    constructor(
        dentalBenefitService: DentalBenefitService,
        private notifyService: NotifyService
    ) {
        super(
            dentalBenefitService
                .getAssignedDentalBenefitCodeCounts()
                .pipe(map((response) => this.mapResponseData(response)))
        );
    }

    handleError = (): void => {
        this.notifyService.error('payroll.dental-benefits.table.error');
    };

    /**
     * Shapes the data from the back-end into the format expected for the table rows
     */
    private mapResponseData(response: DentalBenefitCodeCount): DentalBenefitTableRow[] {
        return Object.entries(response).map(([code, employeesAssignedCount]) => ({
            code,
            description: `payroll.dental-benefits.table.codeDescriptions.${code}`,
            employeesAssignedCount,
        }));
    }
}

@Injectable()
export class DentalBenefitsTable extends Table<DentalBenefitTableRow> {
    paginator!: DentalBenefitsPaginator;
    sortDirection = SortDirection.ASC;
    links = true;
    protected sortProperty = 'code';

    constructor(
        protected auth: AuthService,
        private dentalBenefitService: DentalBenefitService,
        private notifyService: NotifyService
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = columns.map((DentalBenefitsColumn) => new DentalBenefitsColumn());
    }

    setDataSource(): void {
        this.paginator = new DentalBenefitsPaginator(this.dentalBenefitService, this.notifyService);
    }

    sort(column: Column): void {
        // Super call ensures that we update the private variables responsible for the column UI icons that indicate sorting direction
        super.sort(column, false);
        this.paginator.localSort();
    }

    getLink(row: DentalBenefitTableRow): string[] {
        return [row.code];
    }
}
