import { Location, NgTemplateOutlet } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterModule } from '@angular/router';
import { TranslatableKey } from '@app/types/translatable.type';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.template.html',
    standalone: true,
    imports: [TranslateModule, RouterModule, MatIconModule, NgTemplateOutlet],
    styleUrls: ['./back-button.style.scss'],
})
export class BackButtonComponent {
    /**
     * The default back functionality is to go to the last page, this can be overwritten using backRoute
     */
    backRoute = input<RouterLink['routerLink']>();
    /**
     * If instead link functionality it's preferred to perform a function, onBackClick can be used
     */
    onBackClick = input<() => void>();

    backButtonText = input<TranslatableKey>('components.header.back');
    backButtonTextParams = input<Parameters<TranslatePipe['transform']>[1]>();

    constructor(private location: Location) {}

    onBack(): void {
        // Allows for custom back behaviour that might not necessarily be a router event (ie. changing steps in a steps component)
        if (this.onBackClick()) {
            this.onBackClick()?.();
            return;
        }
        this.location.back();
    }
}
