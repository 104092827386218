import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonApiResponse } from '@app/interfaces';
import { RL1_BOX_GROUP_NAMES, Rl1Box } from '@app/models/payroll/rl1-box.model';
import { RL1Summary } from '@app/models/payroll/rl1-summary.model';
import { Rl1 } from '@app/models/payroll/rl1.model';
import { AuthService } from '@app/services';
import { TranslatableKey } from '@app/types/translatable.type';
import { groupBy, sortBy } from 'lodash-es';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class RL1Service {
    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {}

    async generateRL1Summary(rl1Summary?: RL1Summary): Promise<RL1Summary> {
        if (!rl1Summary) {
            rl1Summary = new RL1Summary();
        }

        rl1Summary.defaultContact = {
            name: this.auth.account.fullName || '',
            email: this.auth.account.email,
            phoneNumber: this.auth.company.phonePrimary,
        };

        return await rl1Summary.param('company', this.auth.company.id).save();
    }

    /**
     * Retrieves all of the boxes on an RL1
     */
    getBoxes(taxReturn: Rl1): Observable<{ title?: TranslatableKey; boxes: Rl1Box[] }[]> {
        return from(Rl1Box.limit(100).get()).pipe(
            // RL-1s are grouped according to specific titles which need to be calculated here
            map(([boxes]) => groupBy(boxes, (box) => box.group)),
            map((boxes) =>
                RL1_BOX_GROUP_NAMES.map((key) => ({
                    title: `payroll.taxForms.RL1.employee.boxTitles.${key}`,
                    boxes: sortBy(boxes[key], (box) => taxReturn.errors[box.xmlNameCamel] == undefined),
                }))
            )
        );
    }

    async addAmendedRL1(year: number, prEmployeeId: number | string): Promise<Rl1> {
        const rl1 = new Rl1();
        rl1.year = year;
        rl1.employeeId = prEmployeeId.toString();

        return await rl1.param('company', this.auth.company.id).save();
    }

    /**
     * Reverts any fields modified by the user to its original generated state
     */
    revertToOriginalState(rl1: Rl1): Observable<Rl1> {
        const path = PayrollResources.RL1Revert.replace(':company', this.auth.company.id.toString()).replace(
            ':rl1',
            rl1.id.toString()
        );

        return this.http.put<Rl1>(path, {});
    }

    issue(summary: RL1Summary, manualSubmit: boolean): Observable<RL1Summary> {
        const url = `/v2/payroll/companies/${this.auth.company.id}/rl1Summaries/${summary.id}/issue`;

        return this.http
            .put<JsonApiResponse<RL1Summary>>(url, { data: { attributes: { manualSubmit } } })
            .pipe(map((response) => new RL1Summary(response.data.attributes)));
    }
}
