import { ModelMixin } from '../core/base-generic.model';
import { HumiInquiryStatus, PersonaInquiryDetails, PersonaInquiryStatus } from './types';

/**
 * Humi consolidates all of the various statuses returned from Persona into just three possibilities
 */
export const HUMI_PERSONA_STATUS_MAPPING: Record<PersonaInquiryStatus, HumiInquiryStatus> = {
    created: 'not_complete',
    expired: 'not_complete',
    pending: 'not_complete',
    declined: 'not_complete', // Allows declined inquiries to restart the inquiry flow from scratch
    failed: 'in_progress',
    needs_review: 'in_progress',
    approved: 'complete',
    completed: 'complete',
};

export class PersonaAccount extends ModelMixin<PersonaAccount>() {
    get environmentId(): string {
        return this._attributes['environmentId'];
    }

    get templateId(): string {
        return this._attributes['templateId'];
    }

    get accountId(): string {
        return this._attributes['accountId'];
    }

    get inquiry(): PersonaInquiryDetails | undefined {
        return this._attributes['inquiry'];
    }

    get status(): HumiInquiryStatus {
        if (this.inquiry?.status) {
            return HUMI_PERSONA_STATUS_MAPPING[this.inquiry.status];
        }
        return 'not_complete';
    }

    protected static _serializeAttributes = ['accountId', 'environmentId', 'templateId', 'inquiry'];

    protected static _resource = 'commonComponents/fetchPersonaAccount/:entityType/:entityId' as const;
    protected static _version = 'v2';
}
