import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Model } from '@app/models/core/base.model';
import { ColDef, GridOptions } from 'ag-grid-community';
import { SearchComponent } from '../forms/search/search.component';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.template.html',
    styleUrls: ['./grid.style.scss'],
})
export class GridComponent {
    @ViewChild('searchBar') searchBar: SearchComponent;
    @Input() rowData: Model[] = [];
    @Input() columnDefs: ColDef[] = [];
    @Input() returnRawColumnEditEvent = false;
    @Input() undoRedoCellEditing = false;
    @Input() showSearch = true;
    @Input() showSaveState = true;
    @Input() allChangesSaved = true;
    @Input() displayErrorState = false;
    @Input() errorString = '';
    @Input() gridOptions: GridOptions = {
        suppressPropertyNamesCheck: true,
    };

    @Output() cellStartEditEmitter: EventEmitter<Model> = new EventEmitter<Model>();
    @Output() cellStartEditEmitterRawReturn: EventEmitter<any> = new EventEmitter<any>();
    @Output() cellEditEmitter: EventEmitter<Model> = new EventEmitter<Model>();
    @Output() cellEditEmitterRawReturn: EventEmitter<any> = new EventEmitter<any>();
    @Output() cellPasteEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() gridSearchEmitter: EventEmitter<any> = new EventEmitter<any>();

    gridApi;
    gridColumnApi;

    defaultColDef;

    constructor() {
        this.defaultColDef = {
            floatingFilter: false,
            resizable: true,
        };
    }

    onSearch(query: string): void {
        this.gridSearchEmitter.emit(query);
    }

    onCellEditingStarted(cellEditEvent: any): void {
        if (!this.returnRawColumnEditEvent) {
            this.cellStartEditEmitter.emit(cellEditEvent.data);
            return;
        }
        this.cellStartEditEmitterRawReturn.emit(cellEditEvent);
    }

    onCellEditingStopped(cellEditEvent: any): void {
        if (!this.returnRawColumnEditEvent) {
            this.cellEditEmitter.emit(cellEditEvent.data);
            return;
        }
        this.cellEditEmitterRawReturn.emit(cellEditEvent);
    }

    onCellPasted(cellPasteEvent: any): void {
        this.cellPasteEmitter.emit(cellPasteEvent);
    }

    onGridReady(params): void {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    undoCellEdit(): void {
        this.gridApi.undoCellEditing();
    }
}
