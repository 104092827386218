import { Component, ElementRef, Input } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { BulkSelectBaseDialogComponent } from '../bulk-select-base-dialog/bulk-select-base-dialog.component';
import { TrainingProgram } from '@app/models/training/training-program.model';
import { NotifyService } from '@app/services';

@Component({
    selector: 'app-common-bulk-select-training-programs',
    templateUrl: './bulk-select-training-programs.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectTrainingProgramsComponent extends BulkSelectBaseDialogComponent {
    @Input() label = 'select';
    @Input() headerText = 'components.bulk-select-training-programs.selectTrainingPrograms';

    items: TrainingProgram[] = [];
    selectItems: TrainingProgram[] = [];

    constructor(
        protected element: ElementRef,
        protected auth: AuthService,
        protected notify: NotifyService
    ) {
        super(element, auth);
    }

    selectAll(): void {
        TrainingProgram.param('company', this.auth.company.id)
            .all()
            .then(([trainingPrograms]) => {
                this.selectItems = trainingPrograms;
                this.updateModelSelection();
            })
            .catch((err) => {
                this.isLoading = false;
                this.notify.error(err.message || 'components.dialog-common.serverError');
            });
    }

    protected loadItems(): void {
        let request = TrainingProgram.param('company', this.auth.company.id).page(this.currentPage);

        request = this.applyQuery(request);

        request
            .get()
            .then(([trainingPrograms, meta]) => {
                this.totalResults = meta.pagination.total;
                this.items = trainingPrograms;
                this.isLoading = false;
            })
            .catch((err) => {
                this.isLoading = false;
                this.notify.error(err.message || 'components.dialog-common.serverError');
            });
    }
}
