import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { T4 } from '@app/models/payroll/t4.model';
import { AuthService } from '@app/services';
import { PayrollResources } from '@payroll/payroll.resources';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface T4Notifications {
    employees: T4Notification[];
}

export type T4Notification = {
    name: string;
    hrEmployeeId: number;
    id: number;
    boxes: string[];
};

@Injectable()
export class T4NotificationsService {
    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {}

    /**
     * Retrieves all T4 Notifications for an entire T4 Summary
     */
    getSummaryNotifications(t4SummaryId: string | number): Observable<T4Notifications> {
        return this.http
            .get<{
                data: { attributes: T4Notifications };
            }>(
                PayrollResources.T4SummaryNotifications.replace(':company', this.auth.company.id.toString()).replace(
                    ':t4_summary',
                    t4SummaryId.toString()
                )
            )
            .pipe(map(({ data }) => data?.attributes));
    }

    /**
     * Retrieves all T4 Notifications for an individual employee T4 slip
     */
    getEmployeeNotifications(t4: T4): Observable<T4Notification | undefined> {
        return this.getSummaryNotifications(t4.summaryId).pipe(
            map(({ employees }) => employees.find(({ id }): boolean => id === t4.employeeId))
        );
    }
}
