import { Injectable } from '@angular/core';
import { Platform } from '@app/classes';
import { MenuItem, MenuItemGroup } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';
import { BenefitPlan } from '@models/benefits/benefit-plan.model';

/**
 * Root Benefits menu service for navigating plans
 */
@Injectable()
export class BenefitsMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = BenefitsMenu;
    }
}

/**
 * Benefit menu service for navigating benefits within plans
 */
@Injectable()
export class BenefitShowMenuService extends MenuService {
    _hasEnhancedManagedBenefits?: boolean;

    public set hasEnhancedManagedBenefits(hasEnhancedManagedBenefits: boolean) {
        // No need to refresh the menu if the value hasn't changed
        if (hasEnhancedManagedBenefits === this._hasEnhancedManagedBenefits) {
            return;
        }

        this._hasEnhancedManagedBenefits = hasEnhancedManagedBenefits;
        this.updateMenu(); // reapplies filterMenu
    }
    public get hasEnhancedManagedBenefits(): boolean {
        return !!this._hasEnhancedManagedBenefits;
    }

    setBaseMenu(): void {
        this.baseMenu = BenefitShowMenu;
    }

    protected filterMenu(menu: MenuItemGroup): MenuItemGroup {
        if (this.hasEnhancedManagedBenefits) {
            return menu;
        }

        return menu.filter((item) => item.key !== 'Change Requests');
    }
}

const BenefitsMenu: MenuItem[] = [
    {
        key: 'benefits.plans',
        link: ['/benefits', 'plans'],
        can: [...Platform.benefits.permission.view, ...BenefitPlan.permission.view],
    },
    {
        key: 'benefits.payrollSettings',
        link: ['/benefits', 'settings'],
        can: [...Platform.benefits.permission.view, ...BenefitPlan.permission.edit],
    },
];

const BenefitShowMenu: MenuItem[] = [
    {
        key: 'Plan',
        link: ['/benefits', 'plans', ':benefitPlan'],
        routerLinkExact: true,
    },
    {
        key: 'Employees',
        link: ['/benefits', 'plans', ':benefitPlan', 'employees'],
        routerLinkExact: true,
    },
    {
        key: 'Change Requests',
        link: ['/benefits', 'plans', ':benefitPlan', 'change-requests'],
        routerLinkExact: true,
        can: ['administrate'],
    },
];
