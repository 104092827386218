import { Injectable } from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { MenuItem } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';

const PayrollModuleMenu: MenuItem[] = [
    {
        key: 'payroll.menuItemLabels.runPayroll',
        link: ['/payroll', 'run'],
    },
    {
        key: 'payroll.menuItemLabels.records',
        link: ['/payroll', 'records'],
        children: [
            {
                key: 'payroll.menuItemLabels.payrolls',
                link: ['/payroll', 'records', 'payrolls'],
            },
            {
                key: 'payroll.menuItemLabels.taxReturns',
                link: ['/payroll', 'records', 'tax-returns'],
            },
            {
                key: 'payroll.menuItemLabels.roes',
                link: ['/payroll', 'records', 'roes'],
            },
            {
                key: 'payroll.menuItemLabels.ytds',
                link: ['/payroll', 'records', 'ytds'],
            },
            {
                key: 'payroll.menuItemLabels.vacationYtds',
                ableTo: 'vacationYTDNavigable',
                showIfHasFeatureFlag: FeatureFlag.timeOffPayrollIntegrationPhaseFour,
                link: ['/payroll', 'records', 'vacation-ytds'],
            },
            {
                key: 'payroll.menuItemLabels.vacationStartingBalances',
                ableTo: 'vacationYTDNavigable',
                showIfHasFeatureFlag: FeatureFlag.vacationAccrualAndPayout,
                link: ['/payroll', 'records', 'vacation-ytds'],
            },
            {
                key: 'payroll.menuItemLabels.yearEndAdjustment',
                showIfHasFeatureFlag: FeatureFlag.taxAdjuster,
                link: ['/payroll', 'records', 'adjustments'],
            },
        ],
    },
    {
        key: 'payroll.menuItemLabels.benefitsAndDeductions',
        link: [],
        children: [
            {
                key: 'payroll.menuItemLabels.benefits',
                link: ['/payroll', 'benefits'],
            },
            {
                key: 'payroll.menuItemLabels.deductions',
                link: ['/payroll', 'deductions'],
            },
            {
                key: 'payroll.menuItemLabels.dental-benefits',
                showIfHasFeatureFlag: FeatureFlag.bulkUpdateBox45,
                link: ['/payroll', 'dental-benefits'],
            },
        ],
    },
    {
        key: 'payroll.menuItemLabels.incomeTypes',
        link: ['/payroll', 'additional-incomes'],
    },
    {
        key: 'payroll.menuItemLabels.vacationAdjustments',
        ableTo: 'vacationYTDNavigable',
        link: ['/payroll', 'vacation-ytd-adjuster'],
    },
    {
        key: 'payroll.menuItemLabels.workersCompensation',
        showIfHasFeatureFlag: FeatureFlag.workersCompensationRate,
        link: ['/payroll', 'workers-compensations'],
    },
    {
        key: 'payroll.menuItemLabels.settings',
        link: ['/payroll', 'settings'],
    },
    {
        key: 'payroll.menuItemLabels.payrollSetup',
        ableTo: 'payrollSetupNavigable',
        link: [],
        children: [
            {
                key: 'payroll.menuItemLabels.companyInformation',
                link: ['/payroll', 'self-onboarding', 'company-information'],
            },
            {
                key: 'payroll.menuItemLabels.authorizationDocuments',
                link: ['/payroll', 'self-onboarding', 'authorization-documents'],
            },
            {
                key: 'payroll.menuItemLabels.supportingDocuments',
                link: ['/payroll', 'self-onboarding', 'supporting-documents'],
                hideIfHasFeatureFlag: FeatureFlag.KYC_KYB,
            },
            {
                key: 'payroll.kyc-kyb.payroll-setup.title',
                link: ['/payroll', 'self-onboarding', 'business-verification'],
                showIfHasFeatureFlag: FeatureFlag.KYC_KYB,
            },
        ],
    },
];

const BenefitMenu: MenuItem[] = [
    {
        key: 'payroll.menuItemLabels.benefit',
        link: ['/payroll', 'benefits', ':benefit'],
        routerLinkExact: true,
    },
    {
        key: 'payroll.menuItemLabels.employees',
        link: ['/payroll', 'benefits', ':benefit', 'employees'],
        routerLinkExact: true,
    },
];

const DeductionMenu: MenuItem[] = [
    {
        key: 'payroll.menuItemLabels.deduction',
        link: ['/payroll', 'deductions', ':deduction'],
        routerLinkExact: true,
    },
    {
        key: 'payroll.menuItemLabels.employees',
        link: ['/payroll', 'deductions', ':deduction', 'employees'],
        routerLinkExact: true,
    },
];

@Injectable()
export class PayrollBenefitsMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = BenefitMenu;
    }
}

@Injectable()
export class PayrollDeductionsMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = DeductionMenu;
    }
}

@Injectable()
export class PayrollModuleMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = PayrollModuleMenu;
    }
}
