import { ModelMixin } from '@models/core/base-generic.model';
import { Rl1 } from './rl1.model';
import { T4Box } from './t4-box.model';
import { TaxFormBox } from './tax-form-box.abstract.model';

export class Rl1Box extends ModelMixin<Rl1Box>() implements TaxFormBox {
    protected static _resource = 'payroll/rl1Boxes';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt'];

    get code(): string {
        return this._attributes['code'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get other(): boolean {
        return this._attributes['other'];
    }

    get inEmploymentIncome(): boolean {
        return this._attributes['inEmploymentIncome'];
    }

    get taxYear(): number {
        return this._attributes['taxYear'];
    }

    get xmlName(): string | null {
        return this._attributes['xmlName'];
    }

    get xmlNameCamel(): keyof Rl1 {
        return this.camelize(this.xmlName) as keyof Rl1;
    }

    get isCurrency(): boolean {
        return true;
    }

    /**
     * boxNumber is equivalent to code for RL-1s (T4s have these fields separated)
     */
    get boxNumber(): string {
        return this.code;
    }

    isRL1(): this is Rl1Box {
        return true;
    }
    isT4(): this is T4Box {
        return false;
    }

    get group(): Rl1BoxGroup {
        if (RL1_BOX_GROUPS.employmentIncome.includes(this.code)) {
            return 'employmentIncome';
        }
        if (RL1_BOX_GROUPS.caseO.includes(this.code)) {
            return 'caseO';
        }
        return 'additionalInfo';
    }

    private camelize(str: string | null): string {
        //TODO: BE currently returning null for xmlName
        if (!str) {
            return '';
        }
        return str.replace(/(\_\w)/g, function (k: string): string {
            return k[1].toUpperCase();
        });
    }
}

/**
 * RL-1s are grouped according to specific headers, these are the three possible groups
 */
export const RL1_BOX_GROUP_NAMES = ['employmentIncome', 'caseO', 'additionalInfo'] as const;
export type Rl1BoxGroup = (typeof RL1_BOX_GROUP_NAMES)[number];

/**
 * A mapping of RL1Box codes and what group they belong to. If they are not specified in a group, they will default to additionalInfo
 */
const RL1_BOX_GROUPS: Omit<Record<Rl1BoxGroup, Rl1Box['code'][]>, 'additionalInfo'> = {
    employmentIncome: [
        'A',
        'B.A',
        'B.B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'V',
        'W',
    ],
    caseO: [
        'O-CA',
        'O-CB',
        'O-CC',
        'O-CD',
        'O-RA',
        'O-RB',
        'O-RC',
        'O-RD',
        'O-RG',
        'O-RH',
        'O-RI',
        'O-RJ',
        'O-RK',
        'O-RL',
        'O-RM',
        'O-RN',
        'O-RO',
        'O-RP',
        'O-RQ',
        'O-RR',
        'O-RS',
        'O-RT',
        'O-RU',
        'O-RV',
        'O-RX',
        'O-RZ',
    ],
};
