@if (backRoute()) {
    <a [routerLink]="backRoute()"><ng-container [ngTemplateOutlet]="backButtonContent" /></a>
} @else {
    <a role="link" (click)="onBack()"><ng-container [ngTemplateOutlet]="backButtonContent" /></a>
}
<ng-content select=".back-container-content"></ng-content>

<ng-template #backButtonContent>
    <mat-icon svgIcon="chevronLeft" />{{ backButtonText() | translate: backButtonTextParams() }}
</ng-template>
