/**
 * These values are used to register icons with the material icon service.
 */
export const ShapeIcons = [
    { name: 'addRepeat', path: '/assets/shape-icons/Icon_Add_Repeat.svg' },
    { name: 'airplane', path: '/assets/shape-icons/Icon_Airplane.svg' },
    { name: 'alarm', path: '/assets/shape-icons/Icon_Alarm.svg' },
    { name: 'ameegoColour', path: '/assets/shape-icons/Icon_Ameego_A_Colour.svg' },
    { name: 'arrowDown', path: '/assets/shape-icons/Icon_Arrow_Down.svg' },
    { name: 'arrowLeft', path: '/assets/shape-icons/Icon_Arrow_Left.svg' },
    { name: 'arrowRight', path: '/assets/shape-icons/Icon_Arrow_Right.svg' },
    { name: 'arrowUp', path: '/assets/shape-icons/Icon_Arrow_Up.svg' },
    { name: 'attach', path: '/assets/shape-icons/Icon_Attach.svg' },
    { name: 'birthdayCake', path: '/assets/shape-icons/Icon_Birthday_Cake.svg' },
    { name: 'boldHelp', path: '/assets/shape-icons/Icon_Bold_Help.svg' },
    { name: 'boldInfo', path: '/assets/shape-icons/Icon_Bold_Info.svg' },
    { name: 'book', path: '/assets/shape-icons/Icon_Book.svg' },
    { name: 'briefcase', path: '/assets/shape-icons/Icon_Brief_Case.svg' },
    { name: 'bug', path: '/assets/shape-icons/Icon_Bug.svg' },
    { name: 'building', path: '/assets/shape-icons/Icon_Building.svg' },
    { name: 'listBulleted', path: '/assets/shape-icons/Icon_List_Bulleted.svg' },
    { name: 'calendar', path: '/assets/shape-icons/Icon_Calendar.svg' },
    { name: 'calendarDetail', path: '/assets/shape-icons/Icon_Calendar_Detail.svg' },
    { name: 'calendarSuccess', path: '/assets/shape-icons/Icon_CalendarSuccess.svg' },
    { name: 'cancel', path: '/assets/shape-icons/Icon_Bold_Cancel.svg' },
    { name: 'check', path: '/assets/shape-icons/Icon_Check.svg' },
    { name: 'checkCircle', path: '/assets/shape-icons/Icon_CheckCircle.svg' },
    { name: 'chevronDown', path: '/assets/shape-icons/Icon_Chevron_Down.svg' },
    { name: 'chevronLeft', path: '/assets/shape-icons/Icon_Chevron_Left.svg' },
    { name: 'chevronRight', path: '/assets/shape-icons/Icon_Chevron_Right.svg' },
    { name: 'chevronUp', path: '/assets/shape-icons/Icon_Chevron_Up.svg' },
    { name: 'clock', path: '/assets/shape-icons/Icon_Clock.svg' },
    { name: 'timeTrackingClock', path: '/assets/shape-icons/Icon_Time_Tracking_Clock.svg' },
    { name: 'clockSingleHand', path: '/assets/shape-icons/Icon_Clock_Single_Hand.svg' },
    { name: 'close', path: '/assets/shape-icons/Icon_Close.svg' },
    { name: 'coin', path: '/assets/shape-icons/Icon_Coin.svg' },
    { name: 'collapse', path: '/assets/shape-icons/Icon_collapse.svg' },
    { name: 'complete', path: '/assets/shape-icons/Icon_Complete.svg' },
    { name: 'copy', path: '/assets/shape-icons/Icon_Copy.svg' },
    { name: 'documents', path: '/assets/shape-icons/Icon_Documents.svg' },
    { name: 'download', path: '/assets/shape-icons/Icon_Download.svg' },
    { name: 'dollar', path: '/assets/shape-icons/Icon_Dollar.svg' },
    { name: 'dragAndDrop', path: '/assets/shape-icons/Icon_Drag_And_Drop.svg' },
    { name: 'edit', path: '/assets/shape-icons/Icon_Edit.svg' },
    { name: 'error', path: '/assets/shape-icons/Icon_Error.svg' },
    { name: 'expand', path: '/assets/shape-icons/Icon_Expand.svg' },
    { name: 'file', path: '/assets/shape-icons/Icon_File.svg' },
    { name: 'filter', path: '/assets/shape-icons/Icon_Filter.svg' },
    { name: 'filterFilled', path: '/assets/shape-icons/Icon_Filter_Filled.svg' },
    { name: 'foot', path: '/assets/shape-icons/Icon_Foot.svg' },
    { name: 'fullscreen', path: '/assets/shape-icons/Icon_FullScreen.svg' },
    { name: 'gavel', path: '/assets/shape-icons/Icon_Gavel.svg' },
    { name: 'gift', path: '/assets/shape-icons/Icon_Gift.svg' },
    { name: 'group', path: '/assets/shape-icons/Icon_Group.svg' },
    { name: 'handRaisesStop', path: '/assets/shape-icons/Icon_HandRaisesStop.svg' },
    { name: 'heart', path: '/assets/shape-icons/Icon_Heart.svg' },
    { name: 'help', path: '/assets/shape-icons/Icon_Help.svg' },
    { name: 'helpSimple', path: '/assets/shape-icons/Icon_Help_Simple.svg' },
    { name: 'home', path: '/assets/shape-icons/Icon_Home.svg' },
    { name: 'image', path: '/assets/shape-icons/Icon_Image.svg' },
    { name: 'infinity', path: '/assets/shape-icons/Icon_Infinity.svg' },
    { name: 'info', path: '/assets/shape-icons/Icon_Info.svg' },
    { name: 'infoSimple', path: '/assets/shape-icons/Icon_Info_Simple.svg' },
    { name: 'list', path: '/assets/shape-icons/Icon_List.svg' },
    { name: 'login', path: '/assets/shape-icons/Icon_Login.svg' },
    { name: 'mail', path: '/assets/shape-icons/Icon_Mail.svg' },
    { name: 'mailRounded', path: '/assets/shape-icons/Icon_Rounded_Mail.svg' },
    { name: 'meatballHorizontal', path: '/assets/shape-icons/Icon_Meatball_Horizontal.svg' },
    { name: 'meatballVertical', path: '/assets/shape-icons/Icon_Meatball_Vertical.svg' },
    { name: 'menu', path: '/assets/shape-icons/Icon_menu.svg' },
    { name: 'money', path: '/assets/shape-icons/Icon_Money.svg' },
    { name: 'notification', path: '/assets/shape-icons/Icon_Notification.svg' },
    { name: 'orgChart', path: '/assets/shape-icons/Icon_OrgChart.svg' },
    { name: 'barChart', path: '/assets/shape-icons/Icon_BarChart.svg' },
    { name: 'percent', path: '/assets/shape-icons/Icon_Percent.svg' },
    { name: 'plus', path: '/assets/shape-icons/Icon_Add.svg' },
    { name: 'profile', path: '/assets/shape-icons/Icon_Profile.svg' },
    { name: 'print', path: '/assets/shape-icons/Icon_Print.svg' },
    { name: 'stop', path: '/assets/shape-icons/Icon_Stop.svg' },
    { name: 'questionMark', path: '/assets/shape-icons/Icon_QuestionMark.svg' },
    { name: 'recruiting', path: '/assets/shape-icons/Icon_Recruiting.svg' },
    { name: 'refresh', path: '/assets/shape-icons/Icon_refresh.svg' },
    { name: 'remove', path: '/assets/shape-icons/Icon_Remove.svg' },
    { name: 'loading', path: '/assets/shape-icons/Icon_Loading.svg' },
    { name: 'repeatOnce', path: '/assets/shape-icons/Icon_Repeat_Once.svg' },
    { name: 'rocket', path: '/assets/shape-icons/Icon_Rocket.svg' },
    { name: 'record', path: '/assets/shape-icons/Icon_Record.svg' },
    { name: 'play', path: '/assets/shape-icons/Icon_Play.svg' },
    { name: 'search', path: '/assets/shape-icons/Icon_Search.svg' },
    { name: 'settings', path: '/assets/shape-icons/Icon_Settings.svg' },
    { name: 'speechBubble', path: '/assets/shape-icons/Icon_SpeechBubble.svg' },
    { name: 'star', path: '/assets/shape-icons/Icon_Star.svg' },
    { name: 'starRating', path: '/assets/shape-icons/Icon_StarRating.svg' },
    { name: 'subscribe', path: '/assets/shape-icons/Icon_Subscribe.svg' },
    { name: 'subtract', path: '/assets/shape-icons/Icon_Subtract.svg' },
    { name: 'success', path: '/assets/shape-icons/Icon_Bold_Success.svg' },
    { name: 'trash', path: '/assets/shape-icons/Icon_Trash.svg' },
    { name: 'visibilityEnabled', path: '/assets/shape-icons/Icon_VisibilityEnabled.svg' },
    { name: 'visiblityDisabled', path: '/assets/shape-icons/Icon_VisibilityDisabled.svg' },
    { name: 'warning', path: '/assets/shape-icons/Icon_Bold_Warning.svg' },
    { name: 'wind', path: '/assets/shape-icons/Icon_Wind.svg' },
    { name: 'wrench', path: '/assets/shape-icons/Icon_Wrench.svg' },
    { name: 'lock', path: '/assets/shape-icons/Icon_Lock.svg' },
    { name: 'lock-v2', path: '/assets/shape-icons/Icon_Lock-v2.svg' },
    { name: 'unlock', path: '/assets/shape-icons/Icon_Unlock.svg' },
    { name: 'openInNew', path: '/assets/shape-icons/Icon_OpenInNew.svg' },
    { name: 'folder', path: '/assets/shape-icons/Icon_Folder.svg' },
    { name: 'drive', path: '/assets/shape-icons/Icon_Drive.svg' },
    { name: 'grippy', path: '/assets/shape-icons/Icon_Grippy.svg' },
    { name: 'eye', path: '/assets/shape-icons/Icon_Eye.svg' },
    { name: 'playback', path: '/assets/shape-icons/Icon_Playback.svg' },
    { name: 'bulb', path: '/assets/shape-icons/Icon_Bulb.svg' },
    { name: 'checkFilledCircle', path: '/assets/shape-icons/Icon_CheckFilledCircle.svg' },
    { name: 'thumbsUp', path: '/assets/shape-icons/Icon_ThumbsUp.svg' },
    { name: 'unselectedRadio', path: '/assets/shape-icons/Icon_RadioUnchecked.svg' },
    { name: 'stronglyDisagree', path: '/assets/shape-icons/Icon_StronglyDisagree.svg' },
    { name: 'somewhatDisagree', path: '/assets/shape-icons/Icon_SomewhatDisagree.svg' },
    { name: 'neitherAgreeNorDisagree', path: '/assets/shape-icons/Icon_NeitherAgreeNorDisagree.svg' },
    { name: 'somewhatAgree', path: '/assets/shape-icons/Icon_SomewhatAgree.svg' },
    { name: 'stronglyAgree', path: '/assets/shape-icons/Icon_StronglyAgree.svg' },
    { name: 'thumbUp', path: '/assets/shape-icons/Icon_ThumbUp.svg' },
    { name: 'thumbDown', path: '/assets/shape-icons/Icon_ThumbDown.svg' },
    { name: 'addElement', path: '/assets/shape-icons/Icon_AddElement.svg' },
    { name: 'link', path: '/assets/shape-icons/Icon_Link.svg' },
    { name: 'announcement', path: '/assets/shape-icons/Icon_Company_Management.svg' },
    { name: 'applicantTracker', path: '/assets/shape-icons/Icon_Applicant_Tracker.svg' },
    { name: 'benefits', path: '/assets/shape-icons/Icon_Benefits.svg' },
    { name: 'documentsNotification', path: '/assets/shape-icons/Icon_Documents_Notification.svg' },
    { name: 'goalsAndFeedback', path: '/assets/shape-icons/Icon_Goals_Feedback.svg' },
    { name: 'offerLetter', path: '/assets/shape-icons/Icon_Offer_Letter.svg' },
    { name: 'survey', path: '/assets/shape-icons/Icon_Survey.svg' },
    { name: 'timeOff', path: '/assets/shape-icons/Icon_Time_Off.svg' },
    { name: 'training', path: '/assets/shape-icons/Icon_Training.svg' },
    { name: 'timeTracking', path: '/assets/shape-icons/Icon_Time_Tracking.svg' },
    { name: 'review', path: '/assets/shape-icons/Icon_Review.svg' },
    { name: 'changeRequest', path: '/assets/shape-icons/Icon_Employee_Request.svg' },
    { name: 'general', path: '/assets/shape-icons/Icon_General.svg' },
    { name: 'filterListActive', path: '/assets/shape-icons/Icon_Filter_List_Active.svg' },
    { name: 'filterList', path: '/assets/shape-icons/Icon_Filter_List.svg' },
    { name: 'folderPrivate', path: '/assets/shape-icons/Icon_Folder_Private.svg' },
    { name: 'folderShared', path: '/assets/shape-icons/Icon_Folder_Shared.svg' },
    { name: 'documentFile', path: '/assets/shape-icons/Icon_Document_File.svg' },
    { name: 'solidChevronRight', path: '/assets/shape-icons/Icon_ChevronSolid_Right.svg' },
    { name: 'solidChevronDown', path: '/assets/shape-icons/Icon_ChevronSolid_Down.svg' },
    { name: 'documentImage', path: '/assets/shape-icons/Icon_Document_Image.svg' },
    { name: 'clockSimple', path: '/assets/shape-icons/Icon_Clock_Simple.svg' },
    { name: 'signingTemplate', path: '/assets/shape-icons/Icon_Signing_Template.svg' },
    { name: 'acknowledgement', path: '/assets/shape-icons/Icon_Acknowledgement.svg' },
    { name: 'folderSign', path: '/assets/shape-icons/Icon_Folder_Sign.svg' },
    { name: 'folderLock', path: '/assets/shape-icons/Icon_Folder_Lock.svg' },
    { name: 'folderUpload', path: '/assets/shape-icons/Icon_Folder_Upload.svg' },
] as const;

export const getIconNames = <T extends typeof ShapeIcons>(icons: T = ShapeIcons as T): T[number]['name'][] =>
    icons.map((icon) => icon.name);
