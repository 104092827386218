import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@app/services/auth.service';

/**
 * Directive to check that a permission exists for the user
 *
 * @param permission: String | String[] - A string or array of permissions
 * @param reportee: Boolean - Whether the active view is a reportee or not
 * @param all: boolean - If all permissions need to pass, or just one
 *
 * @return void - Will hide or show the view conditionally
 */
@Directive({
    selector: '[permission]',
    standalone: true,
})
export class PermissionDirective {
    @Input() permissionReportee: boolean;
    @Input() permissionAll = false;
    protected hasView = false;

    constructor(
        protected auth: AuthService,
        protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef
    ) {}

    @Input()
    set permission(checkPermission: string | string[]) {
        this.check(checkPermission);
    }

    protected check(checkPermission: string | string[]): void {
        let allChecksPassed = false;
        if (typeof checkPermission === 'undefined' || !checkPermission) {
            allChecksPassed = this.noPermissions();
        } else if (typeof checkPermission === 'string') {
            allChecksPassed = this.checkSinglePermission(checkPermission);
        } else {
            allChecksPassed = this.checkMultiPermission(checkPermission);
        }

        // Success case
        if (allChecksPassed && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
            return;
        }

        // Failure case
        if (!allChecksPassed && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
            return;
        }
    }

    protected noPermissions(): boolean {
        return true;
    }

    protected checkSinglePermission(checkPermission: string): boolean {
        return this.auth.can(checkPermission);
    }

    protected checkMultiPermission(checkPermission: string[]): boolean {
        let permissionsPassed = 0;
        for (const permission of checkPermission) {
            if (permission.indexOf('reportee') >= 0) {
                if (this.auth.can(permission) && this.permissionReportee) {
                    permissionsPassed++;
                }
            } else {
                if (this.auth.can(permission)) {
                    permissionsPassed++;
                }
            }
        }

        const permissionPassed = this.permissionAll
            ? permissionsPassed === checkPermission.length
            : permissionsPassed > 0;

        if (!permissionPassed && this.hasView) {
            return false;
        } else if (permissionPassed && !this.hasView) {
            return true;
        }
    }
}
