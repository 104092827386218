import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { BadgeType } from '@humi-design-library/components/Badge/types';
import { ModelMixin } from '../core/base-generic.model';
import { T4Summary } from './t4-summary.model';
import { TaxReturnContactInfo, TaxReturnSummary } from './tax-return-summary.abstract.model';

type Status = 'open' | 'issued';

export class RL1Summary extends ModelMixin<RL1Summary, string>() implements TaxReturnSummary {
    protected static _resource = 'payroll/companies/:company/rl1Summaries';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt'];

    protected static _serializeAttributes = [
        'rqConfirmationNumber',
        'totEmpeQppAmt',
        'totEmprQppAmt',
        'totEmpeQpipAmt',
        'totEmprQpipAmt',
        'totItxDdctAmt',
        'year',
        'taxReturnId',
        'defaultContact',
    ];

    private _stateBadgeMap: Record<Status, BadgeType> = {
        open: 'informative',
        issued: 'success',
    };

    isRL1Summary(): this is RL1Summary {
        return true;
    }

    isT4Summary(): this is T4Summary {
        return false;
    }

    get rqConfirmationNumber(): string | null {
        return this._attributes['rqConfirmationNumber'];
    }

    set rqConfirmationNumber(confirmationNumber: string | null) {
        this._attributes['rqConfirmationNumber'] = confirmationNumber;
    }

    get totEmpeQppAmt(): number {
        return this._attributes['totEmpeQppAmt'];
    }

    get totEmprQppAmt(): number {
        return this._attributes['totEmprQppAmt'];
    }

    get totEmpeQpipAm(): number {
        return this._attributes['totEmpeQpipAmt'];
    }

    get totEmprQpipAm(): number {
        return this._attributes['totEmprQpipAmt'];
    }

    get totItxDdctAmt(): number {
        return this._attributes['totItxDdctAmt'];
    }

    get year(): number {
        return this._attributes['year'];
    }

    get state(): Status {
        return this._attributes['state'] ?? 'open'; // TODO: this attribute is not currently being returned
    }

    set state(state: Status) {
        this._attributes['state'] = state;
    }

    get isOpen(): boolean {
        return this.state === 'open';
    }

    get canSubmit(): boolean {
        return !!this._attributes['canSubmit'];
    }

    get isAmendment(): boolean {
        return false; // TODO: amendments unimplemented
    }

    get isErrored(): boolean {
        return false; // TODO: determine error states
    }

    get isSubmitted(): boolean {
        // TODO: determine submitted state
        return this.state !== 'open';
    }

    get submittedAt(): string | null {
        return this._attributes['submittedAt'];
    }

    get completedAt(): string | null {
        return this._attributes['completedAt'];
    }

    get taxReturnId(): string | null {
        return this._attributes['taxReturnId'];
    }

    set defaultContact(contactInfo: TaxReturnContactInfo) {
        this._attributes['defaultContact'] = contactInfo;
    }

    get stateBadge(): BadgeDisplay | undefined {
        if (!this.id) {
            return undefined;
        }

        const label = `payroll.taxReturns.rl1SummaryStatuses.${this.state}`;

        return { label, type: this.state ? this._stateBadgeMap[this.state] : 'default' };
    }

    get manualSelectedAt(): string {
        return this._attributes['manualSelectedAt'];
    }
}
