import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AudioRecorderComponent } from '@app/components';
import { SupportedAudioFormats } from '@app/components/app-audio-recorder/enums/audio-formats.enum';
import { maxBornOnDate, minBornOnDate } from '@app/constants/dates';
import { FeatureFlag } from '@app/enums';
import { makePostalCodeMask } from '@app/functions';
import { GendersCollection, TextMask } from '@app/interfaces';
import { PronounsCollection } from '@app/interfaces/pronouns.interface';
import { DataValue } from '@app/models/employee/data-value.model';
import { Employee } from '@app/models/employee/employee.model';
import { DataFieldGroup } from '@app/models/settings/data-field-group.model';
import { DataField } from '@app/models/settings/data-field.model';
import { DataFieldsService } from '@app/modules/employees/services';
import { AuthService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { mergeDataFieldsById } from '@employees/functions/merge-data-fields-by-id';
import { mergeDataValuesById } from '@employees/functions/merge-data-values-by-id';
import { DataFieldsForm } from '@forms/employees/components';
import { DefaultGroupFieldNamesEnum } from '@settings/enums/default-group-field-names.enum';

@Component({
    selector: 'app-form-setup-personal',
    templateUrl: './setup-personal.form.html',
    providers: [DataFieldsService],
    styleUrls: ['./setup-personal.responsive.scss'],
})
export class SetupPersonalForm implements OnInit {
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild('basicInformationDataFields') basicInformationDataFields: DataFieldsForm;
    @ViewChild('personalInformationDataFields') personalInformationDataFields: DataFieldsForm;
    @ViewChild('addressDataFields') addressDataFields: DataFieldsForm;
    @ViewChild('editDataFieldsForm') editDataFieldsForm: DataFieldsForm | undefined;
    @ViewChild('audioRecorder') audioRecorder: AudioRecorderComponent;
    @ViewChildren('editDataFieldsForm') editDataFieldsForms: QueryList<DataFieldsForm>;
    @Input() formData: any = {};
    @Input() disableIdentityQuestions = false;
    @Output() valid: EventEmitter<any> = new EventEmitter<any>();

    postalCodeMask: TextMask = makePostalCodeMask();
    minBornOnDate = minBornOnDate;
    maxBornOnDate = maxBornOnDate;
    defaultGroupCustomDataValues: DataValue[] = [];
    defaultGroupCustomDataFields: DataField[] = [];
    customDataFields: DataField[] = [];
    customDataFieldGroups: DataFieldGroup[] = [];
    employee: Employee;
    defaultGroupFieldNames = DefaultGroupFieldNamesEnum;
    isValid = false;
    shouldShowEmployeeNamePronunciation = false;
    shouldShowBirthdayVisibility = false;

    audioBlob: Blob | null = null;
    supportedAudioFormat: SupportedAudioFormats | null = null;

    constructor(
        private auth: AuthService,
        private featureService: FeatureService,
        public dataFieldsService: DataFieldsService
    ) {
        this.employee = this.auth.employee;

        this.featureService.has(FeatureFlag.employeeNamePronunciation).then((shouldShow) => {
            this.shouldShowEmployeeNamePronunciation = shouldShow;
        });
    }

    async ngOnInit(): Promise<void> {
        this.formData.isBirthdayPrivate = true;
        this.shouldShowBirthdayVisibility = await this.featureService.has(FeatureFlag.birthdayVisibility);
        await this.setCustomFieldsImprovements();
    }

    setGenders(allGenders: GendersCollection): void {
        this.formData.genders = allGenders;
    }

    setPronouns(allPronouns: PronounsCollection): void {
        this.formData.pronouns = allPronouns;
    }

    setEmployeeNamePronunciation(): void {
        this.formData.audioBlob = this.audioBlob;
        this.formData.supportedAudioFormat = this.supportedAudioFormat;
    }

    emitFormDataAndFormValidation(): void {
        this.formData.customDataFieldValues = this.defaultGroupCustomDataValues;
        this.formData.customDataFields = this.defaultGroupCustomDataFields;
        this.valid.emit({ valid: this.isValid, data: this.formData });
    }

    submit(): void {
        this.form.onSubmit(new Event('submit'));
        this.basicInformationDataFields.form.onSubmit(new Event('submit'));
        this.personalInformationDataFields.form.onSubmit(new Event('submit'));
        this.addressDataFields.form.onSubmit(new Event('submit'));
        this.editDataFieldsForms.forEach((dataFieldsForm) => dataFieldsForm.form.onSubmit(new Event('submit')));
        this.setEmployeeNamePronunciation();
        this.setIsValid();
        this.emitFormDataAndFormValidation();
    }

    setIsValid(): void {
        this.isValid =
            this.form.valid &&
            this.basicInformationDataFields.valid &&
            this.personalInformationDataFields.valid &&
            this.addressDataFields.valid &&
            this.editDataFieldsForms.toArray().every((dataFieldsForm) => dataFieldsForm.form.valid);
    }

    onProvinceChange(): void {
        if (!this.form.controls.canadianPostalCode) return;

        this.form.controls.canadianPostalCode.markAsTouched();
        this.form.controls.canadianPostalCode.updateValueAndValidity();
    }

    private async setCustomFieldsImprovements(): Promise<void> {
        this.defaultGroupCustomDataFields = (await this.dataFieldsService.getEmployeeDataFields())
            .filter((dataField) => dataField.editable)
            .filter((dataField) => {
                if (dataField.dataFieldGroup.component !== 'personal') {
                    return false;
                }
                return dataField.dataFieldGroup.name !== DefaultGroupFieldNamesEnum.emergency_contacts;
            });

        this.defaultGroupCustomDataFields = mergeDataFieldsById(
            this.defaultGroupCustomDataFields,
            this.formData.customDataFields ?? []
        );

        this.customDataFields = this.defaultGroupCustomDataFields.filter(
            (dataField) => dataField.dataFieldGroup.isCustom
        );

        if (this.auth.can(DataValue.permission.view)) {
            const dataFieldIds = this.defaultGroupCustomDataFields.map((dataField) => dataField.id);

            // From the fetched data values, only keep those that are editable, and that have a parent data field.
            this.defaultGroupCustomDataValues = (await this.dataFieldsService.getEmployeeDataValues())
                .filter((dataValue) => dataValue.canEdit)
                .filter((dataValue) => dataFieldIds.includes(dataValue.dataFieldId));

            // If there are some values that have already been submitted, make sure to replace the fetched ones by them
            this.defaultGroupCustomDataValues = mergeDataValuesById(
                this.defaultGroupCustomDataValues,
                this.formData.customDataFieldValues ?? []
            );
        }
        this.setCustomDataFieldGroups();
    }

    private setCustomDataFieldGroups(): void {
        this.customDataFieldGroups = this.dataFieldsService
            .getUniqueGroupsFromDataFields(this.defaultGroupCustomDataFields)
            .filter(
                (dataFieldGroup) =>
                    this.dataFieldsService.getDataFieldsByGroup(dataFieldGroup.id, this.customDataFields).length > 0
            )
            .sort((dataFieldGroup1, dataFieldGroup2) => dataFieldGroup1.orderBy - dataFieldGroup2.orderBy);
    }
}
