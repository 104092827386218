import { Job } from '../company/job.model';
import { ModelMixin } from '../core/base-generic.model';
import { Employee } from '../employee/employee.model';

/**
 * Retrieves all employees assigned to a specific Dental Benefit code (via filter[option]={CODE})
 */
export class AssignedDentalBenefitEmployee extends ModelMixin<Employee>() {
    protected static _resource = 'payroll/companies/:company/employeeListForBox45Code';

    get firstName(): string | null {
        return this._attributes['firstName'];
    }

    get lastName(): string | null {
        return this._attributes['lastName'];
    }

    get fullName(): string {
        return this._attributes['fullName'];
    }

    get avatarId(): number {
        return this._attributes['avatarId'];
    }

    get job(): Job | null {
        return this.hasOne(Job, 'job');
    }

    get prEmployeeId(): number | null {
        return this._attributes['prEmployeeId'];
    }

    get status(): 'active' | 'onboarding' | 'terminated' | null {
        return this._attributes['status'];
    }
}
