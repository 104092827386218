import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { ModelMixin } from '@models/core/base-generic.model';
import { getYear } from 'date-fns';
import parse from 'date-fns/parse';
import { Employee } from '../employee/employee.model';
import { T4 } from './t4.model';
import { TaxForm } from './tax-form.abstract.model';

type State = 'open'; // TODO add more options

/**
 * Defined in RL1 Transmitter
 * https://github.com/Humi-HR/payroll/blob/master/app/models/forms/rq/rl1_transmitter.rb#L10-L12
 */
enum PackageType {
    ORIGINAL = 1,
    AMENDED = 4,
    CANCELLED = 6,
}
export class Rl1 extends ModelMixin<Rl1>() implements TaxForm {
    protected static _resource = 'payroll/rl1s';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'employeeDetails',
        'address1',
        'address2',
        'address3',
        'locality',
        'adminArea',
        'country',
        'postCode',
        'boxA',
        'boxC',
        'boxD',
        'boxE',
        'boxF',
        'boxG',
        'boxH',
        'boxI',
        'boxM',
        'boxN',
        'boxQ',
        'boxR',
        'boxS',
        'boxT',
        'boxJ',
        'boxK',
        'boxL',
        'boxP',
        'boxV',
        'boxW',
        'boxA1',
        'boxA2',
        'boxA3',
        'boxA4',
        'boxA5',
        'boxA6',
        'boxA7',
        'boxA9',
        'boxA10',
        'boxA11',
        'boxA12',
        'boxA13',
        'boxA14',
        'boxB1',
        'boxB2',
        'boxD1',
        'boxD2',
        'boxD3',
        'boxG1',
        'boxG2',
        'boxG3',
        'boxK1',
        'boxL2',
        'boxL3',
        'boxL4',
        'boxL7',
        'boxL8',
        'boxL9',
        'boxL10',
        'boxO2',
        'boxO3',
        'boxO4',
        'boxO5',
        'boxO6',
        'boxO7',
        'boxO8',
        'boxO9',
        'boxO10',
        'boxOCa',
        'boxOCb',
        'boxOCc',
        'boxOCd',
        'boxORa',
        'boxORb',
        'boxORc',
        'boxORd',
        'boxORg',
        'boxORh',
        'boxORi',
        'boxORj',
        'boxORk',
        'boxORl',
        'boxORm',
        'boxORn',
        'boxORo',
        'boxORp',
        'boxORq',
        'boxORr',
        'boxORs',
        'boxORt',
        'boxORu',
        'boxORv',
        'boxORx',
        'boxORz',
        'boxRzXx',
        'boxR1',
        'boxV1',
        'box200',
        'box201',
        'box211',
        'box235',
        'boxBA',
        'boxBB',
        'year',
    ];

    get employeeId(): string {
        return this._attributes['employeeId'];
    }
    set employeeId(val: string) {
        this._attributes['employeeId'] = val;
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get createdAt(): string {
        return this._attributes['createdAt'];
    }

    get updatedAt(): string {
        return this._attributes['updatedAt'];
    }

    get boxA(): string {
        return this._attributes['boxA'];
    }
    set boxA(val: string) {
        this._attributes['boxA'] = val;
    }
    get boxC(): string {
        return this._attributes['boxC'];
    }
    set boxC(val: string) {
        this._attributes['boxC'] = val;
    }
    get boxD(): string {
        return this._attributes['boxD'];
    }
    set boxD(val: string) {
        this._attributes['boxD'] = val;
    }

    get boxE(): string {
        return this._attributes['boxE'];
    }
    set boxE(val: string) {
        this._attributes['boxE'] = val;
    }

    get boxF(): string {
        return this._attributes['boxF'];
    }
    set boxF(val: string) {
        this._attributes['boxF'] = val;
    }

    get boxG(): string {
        return this._attributes['boxG'];
    }
    set boxG(val: string) {
        this._attributes['boxG'] = val;
    }

    get boxH(): string {
        return this._attributes['boxH'];
    }
    set boxH(val: string) {
        this._attributes['boxH'] = val;
    }

    get boxI(): string {
        return this._attributes['boxI'];
    }
    set boxI(val: string) {
        this._attributes['boxI'] = val;
    }

    get boxM(): string {
        return this._attributes['boxM'];
    }
    set boxM(val: string) {
        this._attributes['boxM'] = val;
    }

    get boxN(): string {
        return this._attributes['boxN'];
    }
    set boxN(val: string) {
        this._attributes['boxN'] = val;
    }

    get boxQ(): string {
        return this._attributes['boxQ'];
    }
    set boxQ(val: string) {
        this._attributes['boxQ'] = val;
    }

    get boxR(): string {
        return this._attributes['boxR'];
    }
    set boxR(val: string) {
        this._attributes['boxR'] = val;
    }

    get boxS(): string {
        return this._attributes['boxS'];
    }
    set boxS(val: string) {
        this._attributes['boxS'] = val;
    }

    get boxT(): string {
        return this._attributes['boxT'];
    }
    set boxT(val: string) {
        this._attributes['boxT'] = val;
    }

    get boxJ(): string {
        return this._attributes['boxJ'];
    }
    set boxJ(val: string) {
        this._attributes['boxJ'] = val;
    }

    get boxK(): string {
        return this._attributes['boxK'];
    }
    set boxK(val: string) {
        this._attributes['boxK'] = val;
    }

    get boxL(): string {
        return this._attributes['boxL'];
    }
    set boxL(val: string) {
        this._attributes['boxL'] = val;
    }

    get boxP(): string {
        return this._attributes['boxP'];
    }
    set boxP(val: string) {
        this._attributes['boxP'] = val;
    }

    get boxV(): string {
        return this._attributes['boxV'];
    }
    set boxV(val: string) {
        this._attributes['boxV'] = val;
    }

    get boxW(): string {
        return this._attributes['boxW'];
    }
    set boxW(val: string) {
        this._attributes['boxW'] = val;
    }

    get boxA1(): string {
        return this._attributes['boxA1'];
    }
    set boxA1(val: string) {
        this._attributes['boxA1'] = val;
    }

    get boxA2(): string {
        return this._attributes['boxA2'];
    }
    set boxA2(val: string) {
        this._attributes['boxA2'] = val;
    }

    get boxA3(): string {
        return this._attributes['boxA3'];
    }
    set boxA3(val: string) {
        this._attributes['boxA3'] = val;
    }

    get boxA4(): string {
        return this._attributes['boxA4'];
    }
    set boxA4(val: string) {
        this._attributes['boxA4'] = val;
    }

    get boxA5(): string {
        return this._attributes['boxA5'];
    }
    set boxA5(val: string) {
        this._attributes['boxA5'] = val;
    }

    get boxA6(): string {
        return this._attributes['boxA6'];
    }
    set boxA6(val: string) {
        this._attributes['boxA6'] = val;
    }

    get boxA7(): string {
        return this._attributes['boxA7'];
    }
    set boxA7(val: string) {
        this._attributes['boxA7'] = val;
    }

    get boxA9(): string {
        return this._attributes['boxA9'];
    }
    set boxA9(val: string) {
        this._attributes['boxA9'] = val;
    }

    get boxA10(): string {
        return this._attributes['boxA10'];
    }
    set boxA10(val: string) {
        this._attributes['boxA10'] = val;
    }

    get boxA11(): string {
        return this._attributes['boxA11'];
    }
    set boxA11(val: string) {
        this._attributes['boxA11'] = val;
    }

    get boxA12(): string {
        return this._attributes['boxA12'];
    }
    set boxA12(val: string) {
        this._attributes['boxA12'] = val;
    }

    get boxA13(): string {
        return this._attributes['boxA13'];
    }
    set boxA13(val: string) {
        this._attributes['boxA13'] = val;
    }

    get boxA14(): string {
        return this._attributes['boxA14'];
    }
    set boxA14(val: string) {
        this._attributes['boxA14'] = val;
    }

    get boxB1(): string {
        return this._attributes['boxB1'];
    }
    set boxB1(val: string) {
        this._attributes['boxB1'] = val;
    }

    get boxB2(): string {
        return this._attributes['boxB2'];
    }
    set boxB2(val: string) {
        this._attributes['boxB2'] = val;
    }

    get boxD1(): string {
        return this._attributes['boxD1'];
    }
    set boxD1(val: string) {
        this._attributes['boxD1'] = val;
    }

    get boxD2(): string {
        return this._attributes['boxD2'];
    }
    set boxD2(val: string) {
        this._attributes['boxD2'] = val;
    }

    get boxD3(): string {
        return this._attributes['boxD3'];
    }
    set boxD3(val: string) {
        this._attributes['boxD3'] = val;
    }

    get boxG1(): string {
        return this._attributes['boxG1'];
    }
    set boxG1(val: string) {
        this._attributes['boxG1'] = val;
    }

    get boxG2(): string {
        return this._attributes['boxG2'];
    }
    set boxG2(val: string) {
        this._attributes['boxG2'] = val;
    }

    get boxG3(): string {
        return this._attributes['boxG3'];
    }
    set boxG3(val: string) {
        this._attributes['boxG3'] = val;
    }

    get boxK1(): string {
        return this._attributes['boxK1'];
    }
    set boxK1(val: string) {
        this._attributes['boxK1'] = val;
    }

    get boxL2(): string {
        return this._attributes['boxL2'];
    }
    set boxL2(val: string) {
        this._attributes['boxL2'] = val;
    }

    get boxL3(): string {
        return this._attributes['boxL3'];
    }
    set boxL3(val: string) {
        this._attributes['boxL3'] = val;
    }

    get boxL4(): string {
        return this._attributes['boxL4'];
    }
    set boxL4(val: string) {
        this._attributes['boxL4'] = val;
    }

    get boxL7(): string {
        return this._attributes['boxL7'];
    }
    set boxL7(val: string) {
        this._attributes['boxL7'] = val;
    }

    get boxL8(): string {
        return this._attributes['boxL8'];
    }
    set boxL8(val: string) {
        this._attributes['boxL8'] = val;
    }

    get boxL9(): string {
        return this._attributes['boxL9'];
    }
    set boxL9(val: string) {
        this._attributes['boxL9'] = val;
    }

    get boxL10(): string {
        return this._attributes['boxL10'];
    }
    set boxL10(val: string) {
        this._attributes['boxL10'] = val;
    }

    get boxO2(): string {
        return this._attributes['boxO2'];
    }
    set boxO2(val: string) {
        this._attributes['boxO2'] = val;
    }

    get boxO3(): string {
        return this._attributes['boxO3'];
    }
    set boxO3(val: string) {
        this._attributes['boxO3'] = val;
    }

    get boxO4(): string {
        return this._attributes['boxO4'];
    }
    set boxO4(val: string) {
        this._attributes['boxO4'] = val;
    }

    get boxO5(): string {
        return this._attributes['boxO5'];
    }
    set boxO5(val: string) {
        this._attributes['boxO5'] = val;
    }

    get boxO6(): string {
        return this._attributes['boxO6'];
    }
    set boxO6(val: string) {
        this._attributes['boxO6'] = val;
    }

    get boxO7(): string {
        return this._attributes['boxO7'];
    }
    set boxO7(val: string) {
        this._attributes['boxO7'] = val;
    }

    get boxO8(): string {
        return this._attributes['boxO8'];
    }
    set boxO8(val: string) {
        this._attributes['boxO8'] = val;
    }

    get boxO9(): string {
        return this._attributes['boxO9'];
    }
    set boxO9(val: string) {
        this._attributes['boxO9'] = val;
    }

    get boxO10(): string {
        return this._attributes['boxO10'];
    }
    set boxO10(val: string) {
        this._attributes['boxO10'] = val;
    }

    get boxOCa(): string {
        return this._attributes['boxOCa'];
    }
    set boxOCa(val: string) {
        this._attributes['boxOCa'] = val;
    }

    get boxOCb(): string {
        return this._attributes['boxOCb'];
    }
    set boxOCb(val: string) {
        this._attributes['boxOCb'] = val;
    }

    get boxOCc(): string {
        return this._attributes['boxOCc'];
    }
    set boxOCc(val: string) {
        this._attributes['boxOCc'] = val;
    }

    get boxOCd(): string {
        return this._attributes['boxOCd'];
    }
    set boxOCd(val: string) {
        this._attributes['boxOCd'] = val;
    }

    get boxORa(): string {
        return this._attributes['boxORa'];
    }
    set boxORa(val: string) {
        this._attributes['boxORa'] = val;
    }

    get boxORb(): string {
        return this._attributes['boxORb'];
    }
    set boxORb(val: string) {
        this._attributes['boxORb'] = val;
    }

    get boxORc(): string {
        return this._attributes['boxORc'];
    }
    set boxORc(val: string) {
        this._attributes['boxORc'] = val;
    }

    get boxORd(): string {
        return this._attributes['boxORd'];
    }
    set boxORd(val: string) {
        this._attributes['boxORd'] = val;
    }

    get boxORg(): string {
        return this._attributes['boxORg'];
    }
    set boxORg(val: string) {
        this._attributes['boxORg'] = val;
    }

    get boxORh(): string {
        return this._attributes['boxORh'];
    }
    set boxORh(val: string) {
        this._attributes['boxORh'] = val;
    }

    get boxORi(): string {
        return this._attributes['boxORi'];
    }
    set boxORi(val: string) {
        this._attributes['boxORi'] = val;
    }

    get boxORj(): string {
        return this._attributes['boxORj'];
    }
    set boxORj(val: string) {
        this._attributes['boxORj'] = val;
    }

    get boxORk(): string {
        return this._attributes['boxORk'];
    }
    set boxORk(val: string) {
        this._attributes['boxORk'] = val;
    }

    get boxORl(): string {
        return this._attributes['boxORl'];
    }
    set boxORl(val: string) {
        this._attributes['boxORl'] = val;
    }

    get boxORm(): string {
        return this._attributes['boxORm'];
    }
    set boxORm(val: string) {
        this._attributes['boxORm'] = val;
    }

    get boxORn(): string {
        return this._attributes['boxORn'];
    }
    set boxORn(val: string) {
        this._attributes['boxORn'] = val;
    }

    get boxORo(): string {
        return this._attributes['boxORo'];
    }
    set boxORo(val: string) {
        this._attributes['boxORo'] = val;
    }

    get boxORp(): string {
        return this._attributes['boxORp'];
    }
    set boxORp(val: string) {
        this._attributes['boxORp'] = val;
    }

    get boxORq(): string {
        return this._attributes['boxORq'];
    }
    set boxORq(val: string) {
        this._attributes['boxORq'] = val;
    }

    get boxORr(): string {
        return this._attributes['boxORr'];
    }
    set boxORr(val: string) {
        this._attributes['boxORr'] = val;
    }

    get boxORs(): string {
        return this._attributes['boxORs'];
    }
    set boxORs(val: string) {
        this._attributes['boxORs'] = val;
    }

    get boxORt(): string {
        return this._attributes['boxORt'];
    }
    set boxORt(val: string) {
        this._attributes['boxORt'] = val;
    }

    get boxORu(): string {
        return this._attributes['boxORu'];
    }
    set boxORu(val: string) {
        this._attributes['boxORu'] = val;
    }

    get boxORv(): string {
        return this._attributes['boxORv'];
    }
    set boxORv(val: string) {
        this._attributes['boxORv'] = val;
    }

    get boxORx(): string {
        return this._attributes['boxORx'];
    }
    set boxORx(val: string) {
        this._attributes['boxORx'] = val;
    }

    get boxORz(): string {
        return this._attributes['boxORz'];
    }
    set boxORz(val: string) {
        this._attributes['boxORz'] = val;
    }

    get boxRzXx(): string {
        return this._attributes['boxRzXx'];
    }
    set boxRzXx(val: string) {
        this._attributes['boxRzXx'] = val;
    }

    get boxR1(): string {
        return this._attributes['boxR1'];
    }
    set boxR1(val: string) {
        this._attributes['boxR1'] = val;
    }

    get boxV1(): string {
        return this._attributes['boxV1'];
    }
    set boxV1(val: string) {
        this._attributes['boxV1'] = val;
    }

    get box200(): string {
        return this._attributes['box200'];
    }
    set box200(val: string) {
        this._attributes['box200'] = val;
    }

    get box201(): string {
        return this._attributes['box201'];
    }
    set box201(val: string) {
        this._attributes['box201'] = val;
    }

    get box211(): string {
        return this._attributes['box211'];
    }
    set box211(val: string) {
        this._attributes['box211'] = val;
    }

    get box235(): string {
        return this._attributes['box235'];
    }
    set box235(val: string) {
        this._attributes['box235'] = val;
    }

    get tenantId(): string {
        return this._attributes['tenantId'];
    }
    set tenantId(val: string) {
        this._attributes['tenantId'] = val;
    }

    get boxBA(): string {
        return this._attributes['boxBA'];
    }
    set boxBA(val: string) {
        this._attributes['boxBA'] = val;
    }

    get boxBB(): string {
        return this._attributes['boxBB'];
    }
    set boxBB(val: string) {
        this._attributes['boxBB'] = val;
    }

    get rqFormRl1SummaryId(): string {
        return this._attributes['rqFormRl1SummaryId'];
    }
    get summaryId(): string {
        return this.rqFormRl1SummaryId;
    }

    get state(): State {
        return this._attributes['state'];
    }
    set state(val: State) {
        this._attributes['state'] = val;
    }

    get date(): string {
        return this._attributes['date'];
    }

    get typeOfPackage(): PackageType {
        return this._attributes['typeOfPackage'];
    }

    get year(): number {
        return getYear(this.date);
    }
    set year(val: number) {
        this._attributes['year'] = val;
    }

    get startDate(): Date | undefined {
        const date = parse(this._attributes['startDate']);
        if (isNaN(date.getTime())) return undefined;
        return date;
    }

    get endDate(): Date | undefined {
        const date = parse(this._attributes['endDate']);
        if (isNaN(date.getTime())) return undefined;
        return date;
    }

    get jurisdiction(): string {
        return 'QC';
    }

    get stateBadge(): BadgeDisplay {
        const label = `payroll.taxReturns.rl1SummaryStatuses.${this.state}`;

        switch (this.state) {
            case 'open':
            default:
                return { label, type: 'informative' };
        }
    }

    isRL1(): this is Rl1 {
        return true;
    }
    isT4(): this is T4 {
        return false;
    }

    get isOpen(): boolean {
        return this.state === 'open'; // TODO: Determine which states are actually editable
    }

    get isOriginal(): boolean {
        return this.typeOfPackage === PackageType.ORIGINAL;
    }

    get firstName(): string {
        return this.employeeDetails.firstName;
    }
    set firstName(val: string) {
        this._attributes['employeeDetails'].firstName = val;
    }

    get lastName(): string {
        return this.employeeDetails.lastName;
    }
    set lastName(val: string) {
        this._attributes['employeeDetails'].lastName = val;
    }

    get sin(): string {
        return this.employeeDetails.sin;
    }
    set sin(val: string) {
        this._attributes['employeeDetails'].sin = val;
    }

    get address1(): string {
        return this._attributes['address1'];
    }
    set address1(val: string) {
        this._attributes['address1'] = val;
    }

    get address2(): string {
        return this._attributes['address2'];
    }
    set address2(val: string) {
        this._attributes['address2'] = val;
    }

    get address3(): string {
        return this._attributes['address3'];
    }
    set address3(val: string) {
        this._attributes['address3'] = val;
    }

    get locality(): string {
        return this._attributes['locality'];
    }
    set locality(val: string) {
        this._attributes['locality'] = val;
    }

    get adminArea(): string {
        return this._attributes['adminArea'];
    }
    set adminArea(val: string) {
        this._attributes['adminArea'] = val;
    }

    get country(): string {
        return this._attributes['country'];
    }
    set country(val: string) {
        this._attributes['country'] = val;
    }

    get postCode(): string {
        return this._attributes['postCode'];
    }
    set postCode(val: string) {
        this._attributes['postCode'] = val;
    }

    get errors(): Record<string, string> {
        return this._attributes['errors'] || {};
    }

    private get employeeDetails(): { firstName: string; lastName: string; sin: string } {
        return this._attributes['employeeDetails'];
    }
}
