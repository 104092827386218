import { PersonaScope } from '@app/modules/payroll/components/kyc-kyb/types';
import { ModelMixin } from '../core/base-generic.model';
import { HUMI_PERSONA_STATUS_MAPPING } from './persona-account.model';
import { HumiInquiryStatus, PersonaInquiryStatus } from './types';

export class PersonaInquiry extends ModelMixin<PersonaInquiry>() {
    protected static _resource = 'commonComponents/inquiries/:inquiry' as const;
    protected static _version = 'v2';

    private _children?: PersonaInquiry[];

    get inquiryId(): string {
        return this._attributes['inquiryId'];
    }

    get inquiryType(): Lowercase<PersonaScope> | 'ubo' {
        return this._attributes['inquiryType'];
    }

    get parentInquiryId(): (typeof this)['inquiryId'] | null {
        return this._attributes['parentInquiryId'];
    }

    get status(): HumiInquiryStatus {
        return HUMI_PERSONA_STATUS_MAPPING[this._attributes['status'] as PersonaInquiryStatus];
    }

    get inquiryLink(): string | null {
        return this._attributes['inquiryLink'];
    }

    get firstName(): string | null {
        return this._attributes['firstName'];
    }

    get lastName(): string | null {
        return this._attributes['lastName'];
    }

    get email(): string | null {
        return this._attributes['email'];
    }

    get ownership(): number | null {
        return this._attributes['ownership'];
    }

    get initiatorEmployeeId(): number | null {
        return this._attributes['initiatorEmployeeId'];
    }

    /**
     * Child UBO inquiries
     */
    get children(): PersonaInquiry[] {
        if (this._children) {
            return this._children;
        }
        // The child object needs to be manually constructed due to Humi's model system only expecting relationships in the relationship property
        let children = this._attributes['children'];
        if (!Array.isArray(children)) {
            children = [];
        }
        children = children.map((child: { attributes: PersonaInquiry }) => new PersonaInquiry(child.attributes));
        // Once the children have been constructed, save in a private variable so that we're not constantly re-mapping
        this._children = children;
        return children;
    }
}
