import { PaginatorConfig } from '@app/interfaces/paginator-config.interface';
import { ModelUnion } from '@app/models/core/base.model';
import { Observable } from 'rxjs';
import { Paginator, SortDirection } from './paginator';

/**
 * A simplified version of our existing Paginator.
 * It handles most functionality locally and allows for custom data loading that does not rely on a query.
 * This avoids us needing to define a model and can instead use a re-usable service to pull data.
 */
export class SimplifiedPaginator<TValue = ModelUnion> extends Paginator<TValue> {
    get empty(): boolean {
        return this._empty;
    }

    /**
     * Optionally provide a callback to be executed after successful data retrieval
     */
    protected handleSuccess?: (response: TValue[]) => void;
    /**
     * Optionally provide a callback to be executed after errored data retrieval
     */
    protected handleError?: (error: unknown) => void;

    private _empty = true;
    constructor(
        protected dataGetter: Observable<TValue[]>,
        options: PaginatorConfig = {}
    ) {
        super(undefined);
        if (options.shouldAutoLoadData) {
            this.loadData();
        }
    }

    /**
     * Since this table does not contain pages, we do not need to do the sorting on the back-end and can instead do a local sort for a faster user-experience
     */
    localSort(): void {
        const sortRow = this.sort.column as keyof TValue;
        const sortDirection = this.sort.order === SortDirection.ASC ? 1 : -1;
        this._currentValue.sort((a, b) => (a[sortRow] > b[sortRow] ? sortDirection : sortDirection * -1));
    }

    /**
     * Loads the table data via the provided dataGetter from the constructor
     */
    loadData(): Promise<void> {
        this.onLoadStart();
        return new Promise((resolve, reject) => {
            this.dataGetter.subscribe({
                next: (response) => {
                    this._currentValue = response;
                    this.handleSuccess?.(this._currentValue);
                    this._empty = false;
                    this.onLoadEnd();
                    resolve();
                },
                error: (e: unknown) => {
                    this.handleError?.(e);
                    this._empty = true;
                    this.onLoadEnd();
                    reject(e);
                },
            });
        });
    }
}
